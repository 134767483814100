import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { lockIcon } from '../../../customisableuserprofiles/src/assets';

interface PaywallSubscriptionProps {
}

const PaywallSubscription: React.FC<PaywallSubscriptionProps> = () => {
	const classes = useStyles()

	return (
		<Box className={classes.container}>
			<Box className={classes.wrapper}>
				<img src={lockIcon} alt='Lock Icon' />
				<Typography className={classes.desc}>
					"If you wish to read more paid content, you must first subscribe."
				</Typography>
			</Box>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 10,
		display: 'flex',
		justifyContent: 'center',
		backdropFilter: 'blur(5px)',
	},
	wrapper: {
		marginTop: '60px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '16px',
	},
	desc: {
		fontFamily: 'Manrope',
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: 1.4,
	},
}))

export default PaywallSubscription
