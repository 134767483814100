// Customizable Area Start
import React from "react";
import {
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  IconButton
} from "@material-ui/core";
import { withStyles, ThemeProvider, createTheme, styled } from '@material-ui/core/styles';
import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import { darkBg,lightBg, RightIcon} from "./assets";

const StyledGrid = withStyles({
  container: {
    "& .rec-carousel-item": {
      display: "flex",
      alignItems: "center",
    },
    "& .rec-carousel-wrapper":{
      flexDirection:'row'
    }
  }
})(Grid);

const StyledIcon = styled(IconButton)({
   padding:0,
   marginLeft:-4,
   "&.MuiIconButton-root:hover":{
    background:'unset'
   }
})


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  }

});

const webStyles={
  google:{
    opacity: 0,
    cursor: 'pointer',
    zIndex: 1,
    left: '1.5%',
    position: "absolute" as 'absolute',
    overflow: 'hidden',
    top:'21%',
},
corouselContainer:{
  height:'100vh',
  overflow:"auto"
},
loginCorousel:{
  paddingRight:'10px',
  marginLeft:'auto',
  paddingTop:'30px',
},
productText:{
    fontFamily: 'Manrope',
    fontSize: "2.5625rem",
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '63px',
    textAlign:'center' as 'center'
},
unknownText:{
    fontFamily: 'Manrope',
    fontSize: "1.2rem",
    fontStyle: 'normal',
    fontWeight: 700,
    marginBottom: "3rem",
    textAlign: "right" as 'right',
    cursor: "pointer",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitTextFillColor: 'transparent', 
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
},
optionContainer:{
    borderLeft: "4px solid #7233EC",
    padding: "0.625rem 1.25rem",
    borderRadius: "3px",
    display: "block",
    marginBottom: "1rem"
},
individualText:{
    fontFamily: 'Manrope',
    fontSize: "1.938rem",
    fontWeight: 800,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
priceText:{
    fontFamily: 'Manrope',
    fontSize: "1.125rem",
    color: "#BABABA",
    fontWeight: 700,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left', 
},
priceTextLight:{
  fontFamily: 'Manrope',
  fontSize: "1.125rem",
  color: "#6B6B6B",
  fontWeight: 700,
  letterSpacing: '-0.005em',
  textAlign: 'left' as 'left', 
},

businessContainer:{
    borderLeft: "4px solid #FBBF24",
    padding: "0.625rem 1.25rem",
    borderRadius: "3px",
    display: "block",
    marginBottom: "1rem"
},
businessText:{
    fontFamily: 'Manrope',
    fontSize: "1.938rem",
    fontWeight: 800,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
businessPlan:{
    fontFamily: 'Manrope',
    fontSize: "1.125rem",
    color: "#BABABA",
    fontWeight: 700,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
organisationContainer:{
    borderLeft: "4px solid #D74532",
    padding: "0.625rem 1.25rem",
    borderRadius: "3px",
    display: "block",
    marginBottom: "1rem"
},
organisationText:{
    fontFamily: 'Manrope',
    fontSize: "1.938rem",
    fontWeight: 800,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
freeText:{
    fontFamily: 'Manrope',
    fontSize: "1.125rem",
    color: "#BABABA",
    fontWeight: 700,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
textGridContainer:{
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
    display:'flex',
    alignItems:'center',
    gap:12
},
revenueTextContainer:{
  display:'flex',
  alignItems:'center',
  gap:12
},
iconContainer:{
    background: "#222222",
    width: "2.125rem",
    height: "2.125rem",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1rem"
},
investText:{
  fontFamily: 'Manrope',
  fontSize: "14px",
  maxWidth:"440px",
  width:"100%",
  fontWeight: 500,
  textAlign: 'left' as 'left',
  color: '#6B6B6B',
  lineHeight:'21px'
},
iconsContainer:{
    background: "#222222",
    width: "2.125rem",
    height: "2.125rem",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1rem"
},
revenueText:{
    fontFamily: 'Manrope',
    fontSize: "14px",
    maxWidth:"440px",
    width:"100%",
    fontWeight: 500,
    textAlign: 'left' as 'left',
    color: '#6B6B6B',
},
welcomeToText:{
    textShadow: '0px 3.453px 6.906px rgba(0, 0, 0, 0.03), 0px 6.906px 27.624px rgba(0, 0, 0, 0.06)',
    fontFamily: 'Manrope',
    fontSize: "31px",
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: "2rem",
    letterSpacing: '-0.414px',
    marginBottom: "0.5rem"
},
arrowsText:{
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontSize: "31px",
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: "2rem",
    letterSpacing: '-0.414px',
},
verifiedContainer:{
  display:'flex',flexDirection:'row' as 'row'
  ,justifyContent:'flex-start',alignItems:'center',
  marginBottom:'20px'
},
verifyText:{
    textShadow: '0px 3.453px 6.906px rgba(0, 0, 0, 0.03), 0px 6.906px 27.624px rgba(0, 0, 0, 0.06)',
    fontFamily: 'Manrope',
    fontSize: "18",
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: "1.625rem",
    letterSpacing: '-0.414px',
},
verifyImage:{
  marginLeft:'5px'
},
iconButtons:{
    marginTop: "8px",
    maxWidth:"420px",
    width:"100%",
    height:48,
    fontSize:'14px',
},
createAccountButtons:{
  marginTop: "1.2rem",
  maxWidth:"420px",
  width:"100%",
  height:48,
  fontSize:'14px',
},
iconButton:{
  marginTop: "1.2rem",
  border:'1px solid #8833FF',
  paddingTop:'22px',
  paddingBottom:'22px',
  height:56,
  fontSize:'14px',
  borderRadius:"8px",
  maxWidth:"420px",
},
iconButton1:{
  marginTop: "1.2rem",
  border:'1px solid #8833FF',
  paddingTop:'22px',
  fontSize:'14px',
  borderRadius:"8px",
  height:56,
  paddingBottom:'22px',
  maxWidth:"420px",
  fontWeight:700,
},
termsText:{
    fontFamily: 'Manrope',
    fontSize: "18px",
    fontWeight: 500,
    textAlign: 'left' as 'left',
    lineHeight: "26px",
    marginTop: "1.7rem"
},
arrowsThickText:{
  background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    cursor:'pointer',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 500,
  },
accountText:{
    fontFamily: 'Manrope',
    fontSize: "20px",
    fontWeight: 400,
    textAlign: 'left' as 'left',
    lineHeight: "1.875rem",
    marginTop: "3rem",
    marginBottom: "0px"
},
corousel:{
    top: '50%',
    right: '2rem',
    padding:'0px 5% 0px 10%',
    transform: 'translateY(-50%)',
    display: "flex",
    flexDirection: "column" as 'column',
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    height:0,
},
corouselGridItem:{
    position: "fixed" as 'fixed',
    right: 120,
    zIndex:99999,
    alignItems:'center',
    width:'150px'
},
signupLoginGrid:{
    paddingRight: "1.5rem"
},
signupLoginText:{
   zIndex:9999,
   cursor: "pointer",
    fontWeight: 700,
    marginTop:'26px'
},
cardStyle:{margin:"auto",position:'relative' as 'relative',width:'100%'},
deactivateInfo:{
    display:"flex",
    flexDirection:"column",
    gap:10
},
boldText:{
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize:"28px",
    lineHeight:"42px"
},
goodByeText:{
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize:"12px",
    lineHeight:"19px"
}
  
}

import DeactivateAccountController, {
  Props,
} from "./DeactivatedAccountController";


export default class DeactivateAccount extends DeactivateAccountController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const theme = this.props.checked ? lightTheme : darkTheme;
    const {classes}=this.props 
    return (
      <ThemeProvider theme={theme}>
        <Paper className={this.props.checked? classes?.lightColor:classes?.darkColor}>
          <Grid container style={this.props.checked ? styles?.lightThemeBackground : styles?.darkThemeBackground}>
          <Grid item xs={1} sm={5} md={6} container justifyContent="center" alignItems="center" direction="column">
          </Grid>
              <Grid item xs={10} sm={7} md={6} container justifyContent="center" alignItems="center" alignContent="center" style={webStyles.corouselContainer}>
                  <StyledGrid style={{width:"100%"}} container justifyContent="center" alignItems="center" alignContent="center">
                   <Box sx={webStyles.deactivateInfo}>
                        <Typography style={webStyles.boldText}>
                        Your account is deleted
                        </Typography>
                        <Typography style={webStyles.goodByeText}>
                        Sorry to see you go. #Goodbye
                        </Typography>
                        <Button onClick={this.handleNavigate} color="primary" variant="contained" endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>} style={webStyles.createAccountButtons} id="go-to-signup-btn">{`Got it`}</Button>
                   </Box>
                  </StyledGrid>
              </Grid>
          </Grid>
          </Paper>
      </ThemeProvider>
    );
  }
}

const styles={
  lightThemeBackground:{
    height: "100vh",
    backgroundImage: `url(${lightBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down('xs')]: 
    {backgroundImage: 'none'}
},
darkThemeBackground:{
  height: "100vh",
  backgroundImage: `url(${darkBg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down('xs')]: 
  {backgroundImage: 'none'}
},
lightColor:{
  backgroundColor:'#fff'
},
darkColor:{
  backgroundColor:'#070707'
}
}
export const LandingPageWithStyle=withStyles(styles)(DeactivateAccount)
// Customizable Area End
