// Customizable Area Start
import React, { useRef, useState, RefObject, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'

import screenfull from 'screenfull'
import Controls from './VideoControls'
import { OnProgressProps } from 'react-player/base'
import { isArray } from 'lodash'
import Hls from 'hls.js';

interface CustomVideoPlayerProps {
	videoUrl: string
	videoDownloadUrl: string
	classesName: any
	isShowMini?: boolean
}

// Define a more complete video state interface
interface VideoState {
	pip: boolean
	playing: boolean
	controls: boolean
	light: boolean
	muted: boolean
	played: number
	loaded?: number
	playedSeconds?: number
	loadedSeconds?: number
	duration: number
	playbackRate: number
	volume: number
	loop: boolean
	seeking: boolean
}

const useStyles = makeStyles((theme) => ({
	playerWrapper: {
		width: '100%',
		height: '100%',
		position: 'relative',
	},
}))

const format = (seconds: number) => {
	if (isNaN(seconds)) {
		return `00:00`
	}
	const date = new Date(seconds * 1000)
	const hh = date.getUTCHours()
	const mm = date.getUTCMinutes()
	const ss = date.getUTCSeconds().toString().padStart(2, '0')
	if (hh) {
		return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`
	}
	return `${mm}:${ss}`
}

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
	videoUrl,
	classesName,
	isShowMini,
	videoDownloadUrl,
}) => {
	const classes = useStyles()
	const [videoState, setVideoState] = useState<VideoState>({
		pip: false,
		playing: false,
		controls: false,
		light: false,
		muted: true,
		played: 0,
		duration: 0,
		playbackRate: 1.0,
		volume: 1,
		loop: false,
		seeking: false,
	})
	const [currentQuality, setCurrentQuality] = useState<number | null>(null);
	const [availableQualities, setAvailableQualities] = useState<number[]>([]);
	const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [videoError, setVideoError] = useState<Error | null>(null);
	const [controlsVisible, setControlsVisible] = useState(true);
	const [controlsTimeout, setControlsTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
	const [userPaused, setUserPaused] = useState(false);
	const [autoplayAttempted, setAutoplayAttempted] = useState(false);
	const [isDataSaverMode, setIsDataSaverMode] = useState<boolean>(false);

	useEffect(() => {
		try {
			const dataSaverValue = localStorage.getItem('isDataSaveMode');
			setIsDataSaverMode(dataSaverValue === 'true');
		} catch (error) {
			console.error('Error reading data saver mode from localStorage:', error);
			setIsDataSaverMode(false);
		}
	}, []);

	// Add cleanup for the timeout
	useEffect(() => {
		return () => {
			if (controlsTimeout) {
				clearTimeout(controlsTimeout);
			}
		};
	}, [controlsTimeout]);

	// Update controls visibility when it changes
	useEffect(() => {
		if (controlsRef.current) {
			controlsRef.current.style.visibility = controlsVisible ? 'visible' : 'hidden';
		}
	}, [controlsVisible]);

	const hlsRef = useRef<Hls | null>(null)
	const playerRef: RefObject<any> = useRef(null)
	const playerContainerRef: RefObject<HTMLDivElement> = useRef(null)
	const controlsRef: RefObject<any> = useRef(null)
	const { playing, light, muted, loop, playbackRate, pip, played, volume } =
		videoState

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (!entry.isIntersecting) {
					setVideoState((prevState) => ({ ...prevState, playing: false }));
				} else if (isPlayerReady && !userPaused && !isDataSaverMode) {
					// Auto play when video enters viewport, is ready, and wasn't manually paused
					setVideoState((prevState) => ({ 
						...prevState, 
						playing: true,
						// Ensure video is muted for autoplay to work across browsers
						muted: !autoplayAttempted ? true : prevState.muted 
					}));
					setAutoplayAttempted(true);
				}
			},
			{
				threshold: 0.5, // Increased threshold so video plays when more visible
				rootMargin: '0px', // Can be adjusted to control when autoplay triggers
			}
		);

		if (playerContainerRef.current) {
			observer.observe(playerContainerRef.current);
		}

		return () => {
			if (playerContainerRef.current) {
				observer.unobserve(playerContainerRef.current);
			}
		};
	}, [playerContainerRef, videoState, isPlayerReady, userPaused, autoplayAttempted, isDataSaverMode]);

	useEffect(() => {
		const onFullscreenChange = () => {
			setIsFullScreen(screenfull.isFullscreen);
		};

		screenfull.on('change', onFullscreenChange);

		return () => {
			screenfull.off('change', onFullscreenChange);
		};
	}, []);

    useEffect(() => {
        const isHls = videoUrl.endsWith(".m3u8");

        if (isHls && Hls.isSupported()) {
            // Clean up any existing HLS instance
            if (hlsRef.current) {
                hlsRef.current.destroy();
            }
            
            const hlsInstance = new Hls();
            hlsRef.current = hlsInstance;

            hlsInstance.loadSource(videoUrl);
            const videoElement = playerRef.current?.getInternalPlayer();
            if (videoElement) {
                hlsInstance.attachMedia(videoElement);
            }

            hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {
                const levels = hlsInstance.levels.map((level) => level.height);
                setAvailableQualities(levels);
                // Select video quality based on data saver mode
                if (isDataSaverMode && levels.length > 0) {
									const lowestQuality = Math.min(...levels);
									setCurrentQuality(lowestQuality);
									// Set to lowest level index
									const lowestLevelIndex = levels.indexOf(lowestQuality);
									if (lowestLevelIndex !== -1) {
											hlsInstance.currentLevel = lowestLevelIndex;
									}
								} else {
									let selectedQuality = levels[0];
									if (levels.length >= 2) {
											if (levels.includes(720)) {
													selectedQuality = 720;
											} else {
													const lowerQualities = levels
															.filter((level) => level < 720)
															.sort((a, b) => b - a);
													if (lowerQualities.length > 0) {
															selectedQuality = lowerQualities[0];
													} else {
															const higherQualities = levels
																	.filter((level) => level > 720)
																	.sort((a, b) => a - b);
															if (higherQualities.length > 0) {
																	selectedQuality = higherQualities[0];
															}
													}
											}
									}
									setCurrentQuality(selectedQuality);
								}
            });

            hlsInstance.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
                const newQuality = hlsInstance.levels[data.level].height;
                setCurrentQuality(newQuality);
            });

            hlsInstance.on(Hls.Events.ERROR, (event, data) => {
                if (data.fatal) {
                    switch(data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            hlsInstance.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            hlsInstance.recoverMediaError();
                            break;
                        default:
                            // Cannot recover
                            hlsInstance.destroy();
                            setVideoError(new Error(`HLS fatal error: ${data.details}`));
                            break;
                    }
                }
            });

            return () => {
                if (hlsRef.current) {
                    hlsRef.current.destroy();
                    hlsRef.current = null;
                }
            };
        } else if (!isHls) {
            setAvailableQualities([]);
            setCurrentQuality(null);
            
            // Clean up any existing HLS instance
            if (hlsRef.current) {
                hlsRef.current.destroy();
                hlsRef.current = null;
            }
        }
    }, [videoUrl, isDataSaverMode]);

	const switchQuality = (quality: number) => {
		if (hlsRef.current) {
			const levelIndex = hlsRef.current.levels.findIndex(level => level.height === quality);
			if (levelIndex !== -1) {
				hlsRef.current.currentLevel = levelIndex;
				setCurrentQuality(quality);
			}
		}
	};

	const handlePlayPause = useCallback(() => {
		setVideoState(prevState => {
			const newPlaying = !prevState.playing;
			// If user is pausing the video, mark as user-paused
			if (!newPlaying) {
				setUserPaused(true);
			} else {
				setUserPaused(false);
			}
			return { ...prevState, playing: newPlaying };
		});
	}, []);

	const handleProgress = useCallback((changeState: OnProgressProps) => {
		if (videoState.playing && controlsVisible) {
			// Auto-hide controls after 3 seconds of inactivity during playback
			if (controlsTimeout) {
				clearTimeout(controlsTimeout);
			}
			
			const timeout = setTimeout(() => {
				setControlsVisible(false);
			}, 3000);
			
			setControlsTimeout(timeout);
		}
		
		if (!videoState.seeking) {
			setVideoState(prevState => {
				// Only update if values actually changed
				if (
					prevState.played !== changeState.played ||
					prevState.loaded !== changeState.loaded ||
					prevState.playedSeconds !== changeState.playedSeconds ||
					prevState.loadedSeconds !== changeState.loadedSeconds
				) {
					return { ...prevState, ...changeState };
				}
				return prevState;
			});
		}
	}, [videoState.playing, videoState.seeking, controlsVisible, controlsTimeout]);

	const handleSeekChange = useCallback((e: React.ChangeEvent<{}>, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState(prevState => ({ 
				...prevState, 
				played: parseFloat((newValue / 100).toString()) 
			}));
		}
	}, []);

	const handleSeekMouseDown = useCallback(() => {
		setVideoState(prevState => ({ ...prevState, seeking: true }));
	}, []);

	const handleSeekMouseUp = useCallback((event: React.ChangeEvent<{}>, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState(prevState => ({ ...prevState, seeking: false }));
			playerRef.current.seekTo(newValue / 100, 'fraction');
		}
	}, []);

	const handleVolumeSeekDown = useCallback((event: React.ChangeEvent<{}>, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState(prevState => ({
				...prevState,
				seeking: false,
				volume: parseFloat((newValue / 100).toString())
			}));
		}
	}, []);

	const handleVolumeChange = useCallback((event: any, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState(prevState => ({
				...prevState,
				volume: parseFloat((newValue / 100).toString()),
				muted: newValue === 0 ? true : false,
			}));
		}
	}, []);

	const toggleFullScreen = useCallback(() => {
		if (playerContainerRef.current) {
			screenfull.toggle(playerContainerRef.current);
		}
	}, []);

	const handleMouseMove = useCallback(() => {
		setControlsVisible(true);
		
		if (controlsTimeout) {
			clearTimeout(controlsTimeout);
		}
		
		if (videoState.playing) {
			const timeout = setTimeout(() => {
				setControlsVisible(false);
			}, 3000);
			
			setControlsTimeout(timeout);
		}
	}, [videoState.playing, controlsTimeout]);

	const handleMouseLeave = useCallback(() => {
		if (videoState.playing) {
			setControlsVisible(false);
			
			if (controlsTimeout) {
				clearTimeout(controlsTimeout);
			}
		}
	}, [videoState.playing, controlsTimeout]);

	const handlePlaybackRate = useCallback((rate: number) => {
		setVideoState(prevState => ({ ...prevState, playbackRate: rate }));
	}, []);

	const handleMute = useCallback(() => {
		setVideoState(prevState => ({ ...prevState, muted: !prevState.muted }));
	}, []);

	const currentTime =
		playerRef && playerRef.current
			? playerRef.current.getCurrentTime()
			: '00:00'

	const duration =
		playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00'
	const elapsedTime = format(currentTime)

	const totalDuration = format(duration)

	const handleReady = () => {
		setIsPlayerReady(true);
		setVideoError(null);
	};

	const handleEnded = () => {
		setVideoState({ ...videoState, playing: false });
		setUserPaused(true); // Treat ended videos as manually paused to prevent auto-replay
	};

	const handleError = (error: Error) => {
		console.error("Video playback error:", error);
		setVideoError(error);
	};

	return (
		<div
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			ref={playerContainerRef}
			className={classes.playerWrapper}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			role="region"
			aria-label="Video Player"
			tabIndex={0}
		>
			{videoError && (
				<div 
					style={{ position: 'absolute', color: 'white', background: 'rgba(0,0,0,0.7)', padding: '10px', borderRadius: '5px', zIndex: 10 }}
					role="alert"
				>
					Error loading video. Please try again later.
				</div>
			)}
			<ReactPlayer
				ref={playerRef}
				className={classesName}
				url={videoUrl}
				pip={pip}
				playing={playing}
				controls={false}
				light={light}
				loop={loop}
				playbackRate={playbackRate}
				volume={volume}
				muted={muted}
				onProgress={handleProgress}
				onReady={handleReady}
				onEnded={handleEnded}
				onError={handleError}
				config={{
					file: {
						attributes: {
							crossorigin: 'anonymous',
							'aria-label': 'Video content',
						},
					},
				}}
			/>

			<Controls
				ref={controlsRef}
				onSeek={handleSeekChange}
				onSeekMouseDown={handleSeekMouseDown}
				onSeekMouseUp={handleSeekMouseUp}
				onPlayPause={handlePlayPause}
				playing={playing}
				played={played}
				elapsedTime={elapsedTime}
				totalDuration={totalDuration}
				onMute={handleMute}
				muted={muted}
				onVolumeChange={handleVolumeChange}
				onVolumeSeekDown={handleVolumeSeekDown}
				playbackRate={playbackRate}
				onPlaybackRateChange={handlePlaybackRate}
				onToggleFullScreen={toggleFullScreen}
				volume={volume}
				resolutionList={availableQualities}
				onResolutionChange={switchQuality}
				selectedResolution={currentQuality}
				isShowMini={!!isShowMini}
				isFullScreen={isFullScreen}
				videoUrl={videoDownloadUrl}
			/>
		</div>
	)
}

export default CustomVideoPlayer

// Customizable Area End
