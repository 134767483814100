// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { addMemberBlackIcon, addMemberIcon, groupSettingBlackIcon, groupSettingIcon, memberBlackIcon, memberIcon, sendMessageBlackIcon, sendMessageIcon } from "./assets";
import { TTabGroupScreen } from "./GroupsController";
import { IConversationPermissions } from "../../../blocks/chat/src/ViewChatController";

export const configJSON = require("./config");

interface Setting {
    icon: string;
    title: string;
    keyValue: string;
    navigateText?: string;
    navigateLink?: string;
    subDescription?: string;
    isSelect: boolean;
}

interface RoleSettings {
    role: string;
    keyValue?: string;
    settings: Setting[];
    footerText: string;
}

interface ISettingsState {
    [key: string]: boolean | { [key: string]: boolean };
}

export interface Props {
    isCanSendMessage: boolean;
    checked?: boolean;
    defaultSetting: IConversationPermissions;
    onChangeSetting: (groupSetting: IConversationPermissions, settingKeyValue: string) => void;
    onChangeGroupScreen: (tab: TTabGroupScreen) => void;
}

interface S {
    settingsState: ISettingsState;
}

interface SS {

}

export default class GroupsPermissionController extends BlockComponent<
    Props,
    S,
    SS
> {


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            settingsState: this.initializeSettingsState()
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    initializeSettingsState(): ISettingsState {
        return this.getListSettings().reduce<ISettingsState>((acc, role) => {
            if (role.keyValue) {
                acc[role.keyValue] = role.settings.reduce<{ [key: string]: boolean }>(
                    (objRoleSetting, setting) => {
                        objRoleSetting[setting.keyValue] = setting.isSelect;
                        return objRoleSetting;
                    },
                    {}
                );
            } else {
                role.settings.forEach((setting) => {
                    acc[setting.keyValue] = setting.isSelect;
                });
            }
            return acc;
        }, {});
    }

    getListSettings = () => {
        const listSettings = [
            {
                role: "members",
                settings: [
                    {
                        icon: this.props.checked ? groupSettingBlackIcon : groupSettingIcon,
                        title: "Edit Group Settings",
                        keyValue: "can_edit_group_settings",
                        subDescription: "This includes the group name, icon, description, disappearing message timer.",
                        isSelect: this.props.defaultSetting.can_edit_group_settings
                    },
                    {
                        icon: this.props.checked ? sendMessageBlackIcon : sendMessageIcon,
                        title: "Send Messages",
                        keyValue: "cannot_send_messages",
                        subDescription: "Manage who can send messages in the group.",
                        navigateText: "Click here to manage",
                        navigateLink: "sendMessage",
                        isSelect: this.props.isCanSendMessage
                    },
                ],
            },
        ] as unknown as RoleSettings[];
        return listSettings;
    }

    handleSwitchChange = (roleKeyValue: string, settingKeyValue: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        this.setState((prevState) => {
            const updatedState = { ...prevState.settingsState };

            if (roleKeyValue in updatedState && typeof updatedState[roleKeyValue] === 'object') {
                const nestedSettings = updatedState[roleKeyValue] as { [key: string]: boolean };
                nestedSettings[settingKeyValue] = event.target.checked;
            } else {
                updatedState[settingKeyValue] = event.target.checked;
            }

            return { settingsState: updatedState };
        }, () => {
            const listSetting = { ...this.state.settingsState } as unknown;
            this.props.onChangeSetting(listSetting as IConversationPermissions, settingKeyValue)
        });
    };

}

// Customizable Area End
