import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Start
import React from "react"
import { ownerShipIcon, verifyIcon, verifyOrange, verifyYellow } from "./assets"
import {cloneDeep} from 'lodash'
import {toast} from "react-toastify"
import { BodyMention, Post, PostAttributes, User } from "../../activityfeed/src/ActivityFeedController";
import { notify } from "../../utilities/src/components/CustomToast";
import { sendAPIRequest, TAny, TStatusStripeConnect, validateNumberField } from "../../../components/src/utils";

export interface ISubmit{
  profile_photo:File|undefined
  cover_photo:File|undefined
  name:string
  occupation:string
  website:string
  bio:string
  country:string
}




export interface IHighlights{
  id: string,
  type: string,
  attributes: {
    id: number,
    title: string,
    cover: string,
    created_at: string,
    updated_at: string,
    story_count: number
  }
}

export interface HighlightResponse{
  data:Array<IHighlights>
}
export interface IUserProfileResponse {
  data: IUserProfile
}

export interface IError {
  errors: string[]
}

export interface IUserProfile {
  id: string,
  type: string,
  attributes: IUserProfileAttributes
}

export interface IUserProfileAttributes {
  bio: string,
  cover_photo: string,
  profile_photo: string,
  full_name: string,
  user_name: string,
  id: number,
  user_ownership: boolean,
  user_subscription: string,
  occupation: string,
  location: string,
  website: string,
  followers_count: number,
  following_count: number,
  created_at: string,
  is_saved:boolean,
  is_blocked: boolean,
  is_muted:boolean
  is_following: boolean,
  total_reposts:number,
  is_tailored:boolean,
  is_subscribed: boolean
  cometchat_uid: string;
  stripe_account_connected: TStatusStripeConnect;
  subscription_id: null | number;
}


export interface TailoredResponse{
  messages:Array<string>
  data: IUserProfile
}

export interface IActiveUser{
  fullName: string
id: number
isFollowing: string
profilePhoto: string|null
userName: string
userOwnership: boolean
userSubscription: string
}


export interface PaginationDetails {
  page_number: number
  records_in_this_page: number
  records_per_page: number
  total_pages: number
  total_records: number
}


export interface IPostResponse {
  data: Array<Post>,
  pagination_details: PaginationDetails
}

export interface DeleteApiResponse{
  messgage:string
}

export interface PostResponse{
  data:Post
}

export interface IPostAttributes{
  total_reposts: number;
  is_repost: boolean;
  id: number
  body: string;
  body_mentions: BodyMention[];
  hash_tags: { hash_tags: string, indices: Array<number> }[];
  created_at: string;
  post_by: PostBy;
  media: Media[];
  media_mentions: MediaMentions[];
  is_liked: boolean;
  is_saved: boolean;
  model_name: string;
  total_comments: number;
  total_likes: number;
};
export interface IPost {
  id: string;
  type:string;
  attributes: IPostAttributes
}

export interface PostBy {
  id: number;
  full_name: string;
  user_name: string;
  is_following: boolean;
  profile_photo: string;
  user_ownership: boolean;
  user_subscription: string;
}

export interface Media {
  content_type: string;
  url: string;
  type: string;
  id:string;
}
export interface MediaMentions {
  id: number;
  mention: string;
}


export interface IReportIssue {
  id: string,
  type: string,
  attributes: {
    id: number,
    description: string,
    title: string,
    rank: number
  }
}

export interface ILikePostResponse {
  messages: Array<string>
}

export interface IReporPostResponse {
  data: Array<IReportIssue>
}


export interface IPostActivity{
  impressions: number
  engagements: number;
  detail_expands: number;
  new_followers: number;
  profile_visits: number
}

export interface IPostActivityResponse{
  data:{
    id:string,
    attributes:{
      post_activity:IPostActivity
    }
  }
}

export interface ILikedPost{
  id:string,
  type:string,
  attributes:{
    likeable_type:string
    likeable:PostAttributes
  }
}

export interface ILikedPostListResponse{
  data:Array<ILikedPost>
  pagination_details: PaginationDetails

}

export interface IReportResponse{
  messages:Array<string>
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeIndex: string
  anchorEl: null | HTMLElement;
  isLoggedUser: boolean;
  userProfile: IUserProfile | undefined;
  loading: boolean;
  feed: Array<Post>;
  fetchedPages: number;
  postLoading: boolean;
  pagination: PaginationDetails | undefined;
  LoggedUser: IUserProfile | undefined;
  muteModal: boolean;
  activeUser: User|undefined;
  reportPostMainList: Array<IReportIssue>;
  reportPostSubCatList: Array<IReportIssue>;
  bottompoper: boolean;
  reportingPostId: string;
  showReportPostModal: boolean;
  reportPageLoading: boolean;
  reportIssue: number | undefined
  reportSubIssue: number | undefined
  reportCurrentView: string;
  reportPostLoading: boolean
  accountId: number;
  showComments:boolean;
  showPostActivity:boolean;
  selectedPostComment:Post|undefined;
  ActivePostActivityId:string|undefined
  ActivePostActivity:IPostActivity |undefined
  editProfile:boolean;
  notificationPref:boolean;
  isFollowerList:boolean;
  openFollowerList:boolean;
  storyOpen:boolean
  addHighlight:boolean;
  highlightStories:Array<IHighlights>;
  loadingHightlights:boolean;
  selectedHighlight:IHighlights|undefined
  EditHighlight:boolean
  openPinpost:boolean
  isPinned:boolean
  showRepostModal:boolean;
  showQuote:boolean
  openDeletPost:boolean
  openCommentSetting:boolean;
  openArchives:boolean;
  isReportUser:boolean;
  openShare:boolean;
  shareProfileUrl:string;
  showUnfollowConfirmModal: boolean;
  isShowModalPlan: boolean;
  isShowModalDeletePlan: boolean;
  planPrice: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetPlanCallId: string = "";
  apiCreatePlanCallId: string = "";
  apiUpdatePlanCallId: string = "";
  apiDeletePlanCallId: string = "";
  apiStripeConnectCallId: string = "";
  apiGetUserProfileDataCallId: string = "";
  apiGetUserPostsCallId: string = "";
  followApiCallId: string = "";
  apiGetLoggedUserCallId: string = "";
  blockAccountApiCallId: string = "";
  unblockAccountApiCallId: string="";
  muteUserApiCallId: string = "";
  unMuteUserApiCallId: string = "";
  apiGetReportIssuesCallId: string = "";
  apiGetReportSubIssueCallId: string = "";
  getPostActivityApiCallId:string="";
  apiReportPostSubmitCallId: string = ";"
  likePostApiCallId: string = "";
  savePostApiCallId:string="";
  getAccountHighlightsApiCallId:string="";
  deletePostApiCallId:string="";
  repostId:string="";
  undoRepostId:string="";
  addRemoveUserCustomApiCallId:string="";
  apiGetUserLikedPostsCallId:string="";
  pinUnpinPostApiCallId:string="";
  toggleAddRemoveUserCustomListOnPostId: {id: string, userId: number} = {id: '', userId: 0}
  scrollParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeIndex: 'Arrows',
      accountId: 0,
      anchorEl: null,
      isLoggedUser: false,
      loading: false,
      userProfile: undefined,
      feed: [],
      postLoading: false,
      fetchedPages: 1,
      LoggedUser: undefined,
      pagination: undefined,
      muteModal: false,
      activeUser: undefined,
      reportingPostId: '',
      bottompoper: false,
      showReportPostModal: false,
      reportPageLoading: false,
      reportPostMainList: [],
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportCurrentView: 'main',
      reportPostSubCatList: [],
      reportPostLoading: false,
      showComments:false,
      selectedPostComment:undefined,
      showPostActivity:false,
      ActivePostActivity:undefined,
      ActivePostActivityId:undefined,
      editProfile:false,
      notificationPref:false,
      openFollowerList:false,
      isFollowerList:true,
      storyOpen:false,
      addHighlight:false,
      highlightStories:[],
      loadingHightlights:false,
      selectedHighlight:undefined,
      EditHighlight:false,
      openPinpost:false,
      isPinned:false,
      openDeletPost:false,
      showRepostModal:false,
      showQuote:false,
      openCommentSetting:false,
      openArchives:false,
      isReportUser:false,
      openShare:false,
      shareProfileUrl:'',
      showUnfollowConfirmModal: false,
      isShowModalPlan: false,
      isShowModalDeletePlan: false,
      planPrice: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleResponse(apiRequestCallId, responseJson)

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      loading:true
    })
    this.getUserProfile()
    this.getLoggedUserData()
  }


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.props.navigation.getParam('navigationBarTitleText') !== prevProps.navigation.getParam('navigationBarTitleText')) {
      this.setState({
        fetchedPages: 1,
        loading:true,
        feed: [],
        activeIndex:'Arrows',
        userProfile:undefined,
        pagination: undefined
      })
      this.getUserProfile()
      this.getLoggedUserData()
    }
  }

  getPostAcitivityData = async(id?:string) =>{
    let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.jsonApiContentType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getPostActivityApiCallId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.postaActivityEndpoint + `?post_id=${id}`);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAccountHighlights = async() =>{
    this.setState({
      loadingHightlights:true
    })
    let token = await getStorageData("authToken")
    const userId = this.props.navigation.getParam('navigationBarTitleText')
  const header = {
    "Content-Type": configJSON.jsonApiContentType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAccountHighlightsApiCallId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getAccountHighlightEndPoint + `?account_id=${userId}`);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleLikePost = async (id: string, type: string, likeable_type: string) => {
    this.setState({ accountId: JSON.parse(id) })
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      likeable_id: id,
      likeable_type: likeable_type
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.likePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type == "like" ? configJSON.httpPostMethod : configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSubmitReport = async () => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let endpoint=this.state.isReportUser? configJSON.ReportUser:configJSON.ReportPost

    let httpBody = this.state.isReportUser?{
        "report": {
          "reported_account_id": this.state.userProfile?.id,
          "report_issue_id": this.state.reportIssue,
          "report_sub_issue_id": this.state.reportSubIssue
        }
    }
    :
    {
      "report": {
        "post_id": Number(this.state.reportingPostId),
        "report_issue_id": this.state.reportIssue,
        "report_sub_issue_id": this.state.reportSubIssue
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiReportPostSubmitCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleBlockAccount = async (id: number) => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      account_id: id,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.blockAccountEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleUnblockAccount=async(id:number)=>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      account_id: id,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unblockAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.unblockAccountEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleUnmuteUser = async (id: string) => {
    let token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": configJSON.jsonApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let httpBody = {
      account_id: id
    }

    this.unMuteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.unmuteUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  handleFollow = async (id: number, type: string) => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      account_id: id,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.followEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type == "follow" ? configJSON.httpPostMethod : configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  getUserPosts = async () => {
    const userId = this.props.navigation.getParam('navigationBarTitleText')
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetUserPostsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getUserPosts}?page=${this.state.fetchedPages}&per_page=10&account_id=${userId}&sort_order=desc`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRepliedUserPosts=async()=>{
    const userId = this.props.navigation.getParam('navigationBarTitleText')
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetUserPostsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getUserRepliedPosts}?page=${this.state.fetchedPages}&per_page=10&account_id=${userId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubscribeUserPost = async () => {
    const token = await getStorageData("authToken");
    const userId = this.props.navigation.getParam('navigationBarTitleText')

    this.apiGetUserPostsCallId = sendAPIRequest(
      `bx_block_posts/subscribers?account_id=${userId}`,
      {
        method: 'GET',
        headers: {
          token: token,
        }
      }
    )
  }

  getLikedUserPost =async()=>{
    const userId = this.props.navigation.getParam('navigationBarTitleText')
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetUserLikedPostsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getUserLikedPost}?page=${this.state.fetchedPages}&per_page=10&account_id=${userId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  getReportUserMainCategories = async () => {
    let token = await getStorageData("authToken");
    this.setState({
      postLoading: true
    })
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportIssuesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getReportIssues)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReportUserSubCategories = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportSubIssueCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getReportSubIssues}${this.state.reportIssue}`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMuteAcoount = async (id: string) => {
    let token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": configJSON.jsonApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let httpBody = {
      account_id: id
    }

    this.muteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.muteUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSavePost = async(id:string,type:string) => {
    let authToken = await getStorageData("authToken")
    const header = {
      token: authToken,
      "Content-Type": configJSON.jsonApiContentType,
    };

    let httpBody = {
      post_id:id,    
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),type=="save"?configJSON.savePostEndpoint:configJSON.unSavePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type=="save"?configJSON.httpPostMethod:configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }  

  getUserProfile = async () => {
    const userId = this.props.navigation.getParam('navigationBarTitleText')
    let token = await getStorageData("authToken");
   
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetUserProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getUserProfile}/${userId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }


  getLoggedUserData = async () => {
    let token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetLoggedUserCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getLoggedUserInfo);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  DeletePostApi=async()=>{
    let token = await getStorageData("authToken")
    const post_id=this.state.reportingPostId
    const header = {
      token: token,
      "Content-Type": configJSON.jsonApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getPost}/${post_id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
   }
  
 updateProfile=async(values:ISubmit)=>{
  let authToken = await getStorageData("authToken")
  const header = {
    token: authToken,
  };

  let formData = new FormData()
  formData.append("full_name",values.name)    
  formData.append("bio",values.bio)
  formData.append("occupation",values.occupation)    
  formData.append("website",values.website)   
  formData.append("location",values.country)

  values.profile_photo && formData.append("profile_photo",values.profile_photo)
  values.cover_photo && formData.append("cover_photo",values.cover_photo)

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetUserProfileDataCallId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.updateProfile);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formData);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpPatchMethod);
  runEngine.sendMessage(requestMessage.id, requestMessage)
 }


 handleRepost = async () => {
  const token = await getStorageData("authToken")
  
  this.repostId = sendAPIRequest(
    `bx_block_posts/repost`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      body: {
        parent_post_id: this.state.selectedPostComment?.id
      },
    }
  )
  this.setState({showRepostModal: false})
}

handleUndoRepost = async () => {
  const token = await getStorageData("authToken")
  const selectedPostComment= this.state.selectedPostComment  as Post
  this.undoRepostId = sendAPIRequest(
    `bx_block_posts/undo_repost`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      body: {
        parent_post_id: selectedPostComment?.attributes.parent_post?.id 
      },
    }
  )
  this.setState({showRepostModal: false})
}


handlePinnedConfirmation=async(confirmation:string)=>{
  if(confirmation!=='cancel'){
  const post_id=this.state.reportingPostId
  const Endpoint= confirmation==="pin"?configJSON.pinPostEndPoint : configJSON.unpinPostEndPoint 
  const type= confirmation==="pin"? configJSON.httpPostMethod : configJSON.httpDeleteMethod
  const token = await getStorageData("authToken")
  this.pinUnpinPostApiCallId = sendAPIRequest(
    Endpoint,
    {
      method: type,
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      body: {
        post_id: post_id 
      },
    })
   }
  this.handleClosePinPost()
}

handleAdduser = async (id: string) => {
  const token = await getStorageData("authToken")
  const url = configJSON.tailoredMemberEndpoint
  const formdata = new FormData();
  formdata.append("member_id",id)

  this.addRemoveUserCustomApiCallId = sendAPIRequest(
    url,
    {
      method: configJSON.httpPostMethod,
      headers: {
        token,
      },
      body: formdata,
    }
  )
}

handleRemoveUser = async(id:string) =>{
  const token = await getStorageData("authToken")
  const url = configJSON.tailoredMemberEndpoint
  const formdata = new FormData();
  formdata.append("member_id", id)

  this.addRemoveUserCustomApiCallId = sendAPIRequest(
    url,
    {
      method: configJSON.httpDeleteMethod,
      headers: {
        token,
      },
      body: formdata,
    }
  )
}


  handleResponse = (apiRequestCallId: string, responseJson:
            PostResponse
            &DeleteApiResponse
            & HighlightResponse
            &IPostActivityResponse
            & ILikePostResponse 
            & string 
            & IReportResponse
            & ILikedPostListResponse
            & IReporPostResponse 
            & IPostResponse 
            & TailoredResponse
            & IUserProfileResponse 
            & IError) => {

    switch (apiRequestCallId) {

      case this.apiGetUserProfileDataCallId:
        return this.handleProfileResponse(responseJson)

      case this.apiGetUserPostsCallId:
        return this.handlePostsResponse(responseJson)

      case this.apiGetLoggedUserCallId:
        return this.handleLoggedUserResponse(responseJson)

      case this.followApiCallId:
        return this.handleFollowResponse(responseJson)

      case this.unblockAccountApiCallId:
        return  this.handleUnblockAccountResponse(responseJson)

      case this.blockAccountApiCallId:
        return this.handleBlockAccountResponse(responseJson)

      case this.muteUserApiCallId:
        return this.handleMuteUserResponse(responseJson)

      case this.unMuteUserApiCallId:
        return this.handleUnmuteUserResponse(responseJson)

      case this.apiGetReportIssuesCallId:
        return this.handleResportIssuesRespones(responseJson)

      case this.apiGetReportSubIssueCallId:
        return this.handleReportSubIssuesResponse(responseJson)

      case this.apiReportPostSubmitCallId:
        return this.handleReportPostSubmitResponse(responseJson)

      case this.likePostApiCallId:
        return this.handleLikePostResponse(responseJson)

      case this.savePostApiCallId:
        return this.handleSavePostResponse(responseJson)  
      
      case this.getPostActivityApiCallId:
        return this.handlePostActivityResponse(responseJson)

      case this.getAccountHighlightsApiCallId:
        return this.handleGetHighlightStories(responseJson)  
      
      case this.deletePostApiCallId:
        return this.handleDeletePostResponse(responseJson)

      case this.repostId:
        return this.handleRepostResponse(responseJson)

      case this.undoRepostId: 
        return this.handleUndoRepostResponse(responseJson)

      case this.pinUnpinPostApiCallId:
        return this.handlePinUnpinPostResponse(responseJson)  

      case this.addRemoveUserCustomApiCallId:
        return this.handleCustomUserResponse(responseJson)

      case this.apiGetUserLikedPostsCallId:
        return this.handleLikedPostResponse(responseJson)

      case this.toggleAddRemoveUserCustomListOnPostId.id:
        return this.handleToggleAddRemoveUserCustomListOnPostRes(responseJson)

      case this.apiCreatePlanCallId: 
        return this.handleResponseCreatePlan(responseJson);

      case this.apiGetPlanCallId: 
        return this.handleResponseGetPlan(responseJson);

      case this.apiUpdatePlanCallId: 
        return this.handleResponseUpdatePlan(responseJson);

      case this.apiDeletePlanCallId: 
        return this.handleResponseDeletePlan(responseJson);
      
      case this.apiStripeConnectCallId: 
        return this.handleResponseStripeConnect(responseJson);
    }

  }

  updateAndFilterLikedPosts = (posts: Post[], likes: ILikedPost[]): Post[] => {
    const likedPostIds = 
        likes.filter(like => like.attributes.likeable_type === "BxBlockPosts::Post").map(like => like.attributes.likeable)
        const likedPostList = [...likedPostIds].map(post=>({
          id:post.id.toString(),
          type:'post',
          attributes:post
        }))
        return [...posts,...likedPostList]
};


  handleLikedPostResponse=(responseJson:ILikedPostListResponse & IError)=>{
    if(!responseJson.errors){
     const likedPostList=   this.updateAndFilterLikedPosts(this.state.feed, responseJson.data)
     this.setState(prev=>({
      feed:likedPostList,
      pagination:responseJson.pagination_details,
      fetchedPages:prev.fetchedPages+1
     })
    )
    }
  }

  handleToggleAddRemoveUserCustomListOnPostRes = (responseJson: any) => {
		const message = responseJson?.messages?.[0]
		const userId = this.toggleAddRemoveUserCustomListOnPostId.userId
		if (message === 'Member added to your tailored accounts list' || message === 'Removed from your tailored accounts list') {
			return
		} else {
			const clonePosts = [...this.state.feed];
			const updatePosts = clonePosts.map((post) => {
			let postBy = post.attributes.post_by;
			if (post.attributes.post_by.id == userId) {
				postBy = {
				...post.attributes.post_by,
				is_tailored: !post.attributes.post_by.is_tailored,
				};
			}
			
			return {
				...post,
				attributes: {
				...post.attributes,
				post_by: postBy,
				},
			};
			});

			this.setState({ feed: updatePosts });
		}
	}

  handleGetHighlightStories=(responseJson:HighlightResponse & IError)=>{
    if(!responseJson?.errors){
      this.setState({
        highlightStories:responseJson.data,
        loadingHightlights:false
      })
    }
  }

  handleCustomUserResponse=(responseJson:TailoredResponse & IError)=>{
    if(!responseJson?.errors){
      toast.success(responseJson.messages[0])
     this.getUserProfile()
     this.handleMenuClose()
    }
  }

  handleCreateHighlight=(highlight:IHighlights)=>{
   const updatedHighlight = this.state.highlightStories.find(item=>item.id===highlight.id)
    if(updatedHighlight){
      const array = this.state.highlightStories.map(item=>item.id===highlight.id?highlight:item)
      this.setState({
        highlightStories:array
      })
    }else{
    this.setState((prev)=>({
      highlightStories:[...prev.highlightStories,{...highlight}]
    }))
   }
  } 



  handlePostActivityResponse=(responseJson:IPostActivityResponse & IError)=>{
    if(!responseJson.errors){
    this.setState({
      ActivePostActivity:responseJson.data?.attributes?.post_activity
    })
  }
  }

  handleClosePostActivityModel =()=>{
    this.setState({showPostActivity:false ,ActivePostActivityId: ""})
  }


  closeReportPostModal = () => {
    this.setState({
      showReportPostModal: false,
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportingPostId: '',
      reportCurrentView: "main",
      reportPostSubCatList: []
    })
  }

  handleRepostResponse = (responseJson: PostResponse & IError) => {
    if (responseJson && responseJson?.data) {
      let feeds: Post[]=[]
      if(this.state.isLoggedUser){
        feeds = [responseJson.data, ...this.state.feed]
      }else{
        feeds= [...this.state.feed]
      }

      const newFeed = feeds.map(post => {
        if (post.id === this.state.selectedPostComment?.id) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              total_reposts: post.attributes.total_reposts + 1
            }
          }
        }
        return post
       });
       return this.setState({ feed: newFeed})
    }
    if (responseJson?.errors?.[0]) {
      toast.error(responseJson?.errors?.[0])
    }
  }


  handleUndoRepostResponse = (responseJson: DeleteApiResponse & IError) => {
    if (!responseJson?.errors) {
      if(this.state.isLoggedUser){
      const updateFeeds = [...this.state.feed].filter(post => post.id !== this.state.selectedPostComment?.id)
      this.setState({ feed: updateFeeds})
      }
    }
  }
  


  handleFollowResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson.errors) {
      this.handleMenuClose()
      let feed = [...this.state.feed]
      let newFeed = feed.map((post) =>
        (post.attributes.post_by.id).toString() === responseJson.data.id
          ? {
            ...post,
            attributes: {
              ...post.attributes,
              post_by: {
                ...post.attributes.post_by,
                is_following: responseJson.data.attributes.is_following,
                is_blocked: responseJson.data.attributes.is_blocked
              },
            },
          }
          : post
      );

      if (responseJson.data?.attributes?.id === this.state.selectedPostComment?.attributes.post_by.id) {
        const updateCommentsPost = newFeed?.find(post => post.attributes.post_by.id === this.state.selectedPostComment?.attributes.post_by.id)
        this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
       }

      this.setState({
        feed: newFeed
      }, this.getUserProfile)
    }
  }



  handleBlockAccountResponse = (responsejson: IUserProfileResponse & IError) => {
    if (!responsejson?.errors) {
      toast.success(`${this.state.userProfile?.attributes.full_name} has blocked successfully`)
      this.setState({
        feed: [],
        fetchedPages:1,
      }, this.getUserProfile)
      this.handleMenuClose() 
    }
  }


  handleUnblockAccountResponse=(responseJson:IUserProfileResponse & IError)=>{
    if(!responseJson?.errors){
      toast.success(`${this.state.userProfile?.attributes.full_name} has unblocked successfully`)
      this.setState({
        feed: [],
        fetchedPages:1,
      },()=>{
        this.getUserProfile()
        this.getUserPosts()
      }) 
      this.handleMenuClose()
    }
 }


  handleLikePostResponse = (responseJson: ILikePostResponse & IError) => {
    if (!responseJson.errors) {
      let feeds = [...this.state.feed]
      let newarray = feeds.map(post => {
        if (post.attributes.id === this.state.accountId) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              is_liked: responseJson.messages[0] == "Liked Successfully" ? true : false,
              total_likes: responseJson.messages[0] == "Liked Successfully" ? post.attributes.total_likes + 1 : post.attributes.total_likes - 1
            }
          };
        }
        return post;
      });
      if (this.state.accountId.toString() === this.state.selectedPostComment?.id) {
        const updateCommentsPost = newarray?.find(post => post.id === this.state.selectedPostComment?.id)
        this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
       }
      this.setState({ feed: newarray })
    }
  }

  handleDeletePostResponse=(responseJson:DeleteApiResponse & IError)=>{
   if(!responseJson.errors){
    let newfeeds = [...this.state.feed]
    let feeds  = newfeeds.filter(post=>post.id!==this.state.reportingPostId)
    if (this.state.reportingPostId.toString() === this.state.selectedPostComment?.id) {
      const updateCommentsPost = feeds?.find(post => post.id === this.state.selectedPostComment?.id)
      this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
     }
     this.setState({ feed: feeds,reportingPostId:'',showComments:false },this.closeDeletePost)
   }
  }

  handleSavePostResponse=(responseJson:IUserProfileResponse & IError)=>{
   if(!responseJson.errors){
    let newfeeds = [...this.state.feed]
    let feeds = newfeeds.map(post => {
      if (post.attributes.id.toString() == responseJson.data.id) {
        return {
               ...post,
               attributes: {
                 ...post.attributes,
                 is_saved: responseJson.data.attributes.is_saved
               }
             };
           }
           return post;
     });
     if (responseJson.data.id === this.state.selectedPostComment?.id) {
      const updateCommentsPost = feeds?.find(post => post.id === this.state.selectedPostComment?.id)
      this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
     }
     this.setState({feed:feeds}) 
   }
  }

  handlePinUnpinPostResponse=(responseJson: PostResponse & IError)=>{
    if(!responseJson.errors){
     let newfeeds = [...this.state.feed]
     let feeds = newfeeds.map(post => {
       if (post.attributes.id.toString() == responseJson.data.id) {
         return {
                ...post,
                attributes: {
                  ...post.attributes,
                  is_pinned: responseJson.data.attributes.is_pinned
                }
              };
            }
            return post;
      });
      if (responseJson.data.id === this.state.selectedPostComment?.id) {
       const updateCommentsPost = feeds?.find(post => post.id === this.state.selectedPostComment?.id)
       this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
      }
      this.setState({feed:feeds}) 
    }
   }

  handlePostsResponse = (responseJson: IPostResponse & IError) => {
    this.setState({
      postLoading: false
    })
    if (!responseJson.errors) {
      this.setState(prev=>({
        fetchedPages: prev.fetchedPages + 1,
        pagination: responseJson.pagination_details,
        feed: [...prev.feed, ...responseJson.data],
      }
    ))
    }
  }

  handleButtompoperlClose = ()=>{
    this.setState(
      {
        bottompoper:false
      }, 
    )
  }

  changeReportView = () => {
    if (this.state.reportIssue !== undefined && this.state.reportSubIssue !== undefined) {
      this.handleSubmitReport()
    } else if (this.state.reportIssue !== undefined) {
      this.setState({
        reportCurrentView: "subcat",
        reportPostLoading: true
      }, this.getReportUserSubCategories)
    }
  }

  handleReportSubIssuesResponse = (responseJson: IReporPostResponse & IError) => {
    if (!responseJson.errors) {
      this.setState({
        reportPostSubCatList: responseJson.data,
        reportPostLoading: false
      })
    }
  }


  handleClickComments = (postId: string) => {
    this.setState((prev) => {
      const selectedPost = prev.feed?.find(post => post.id === postId)
      return {
        ...prev, 
        showComments: true,
        selectedPostComment: selectedPost
      }
    })
    
  }

  handleResportIssuesRespones = (responseJson: IReporPostResponse & IError) => {
    if (!responseJson.errors) {
      this.setState({
        reportPostMainList: responseJson.data,
        postLoading: false
      })
    }
  }

  handleReportPostSubmitResponse = (responseJson: IReportResponse & IError) => {
    if (!responseJson.errors) {
      toast.success(responseJson.messages[0])
      this.setState({
        isReportUser:false,
        reportCurrentView: "submit"
      })
    }
  }

  setReportPostIssue = (id: number) => {
    this.setState({
      reportIssue: id
    })
  }

  setReportPostSubIssues = (id: number) => {
    this.setState({
      reportSubIssue: id
    })
  }
  handleLoggedUserResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson.errors) {
      this.setState({
        LoggedUser: responseJson.data
      },()=>{ 
        this.getAccountHighlights()
        this.getUserPosts()
      })
    }
  }


  handleCloseCommentsModal = () => {
    this.setState({showComments: false, selectedPostComment: undefined})
  }


  handleProfileResponse = (responseJson: IUserProfileResponse & IError) => {
    this.setState({
      loading: false
    })
    if (!responseJson.errors) {
      this.handleProfiles(responseJson)
    }
  }


  handleMuteUserResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson?.errors) {
      this.setState({ muteModal: false, bottompoper: true })
      this.handleMenuClose()
    }
  }

  handleUnmuteUserResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson?.errors) {
      this.setState({ bottompoper: false })
      this.handleMenuClose()
    }
  }


  handleMuteUnmute=()=>{  
   const userProfile =  this.state.userProfile as IUserProfile
   return  userProfile.attributes.is_muted?()=>this.handleUnmuteUser(userProfile.id):this.handleMuteAcoount(userProfile.id)
  }

  handleBlockUnBlockAccount=()=>{
    const userProfile =  this.state.userProfile as IUserProfile
   return  userProfile.attributes.is_blocked?this.handleUnblockAccount(userProfile.attributes.id):this.handleBlockAccount(userProfile.attributes.id)
  }

  handleSubscriberFollow = () => {
    const userProfile = this.state.userProfile as IUserProfile
    if (!userProfile?.attributes.is_following) {
      this.handleFollow(userProfile?.attributes.id, 'follow')
    }
  }
 


  handlefollowUnfollow = () => {
    const userProfile = this.state.userProfile as IUserProfile
    if (userProfile?.attributes.is_following) {
      this.setState({showUnfollowConfirmModal: false})
      this.handleFollow(userProfile?.attributes.id, 'unfollow')
    } else {
      this.handleFollow(userProfile?.attributes.id, 'follow')
    }
  }


  handleProfiles = (responseJson: IUserProfileResponse) => {
    this.setState({
      editProfile:false
    })
    if (responseJson.data.type === "logged_user") {
      this.handleLoggedUserProfile(responseJson)
    } else {
      this.handleOtherUserProfile(responseJson)
    }
  }

  handleLoggedUserProfile = (responseJson: IUserProfileResponse) => {
    this.setState({
      userProfile: responseJson.data,
      isLoggedUser: true,
    }, () => {
      // Handle get profile plan
      this.getPlanLoggedUser();
    })
  }

  handleOtherUserProfile = (responseJson: IUserProfileResponse) => {
    this.setState({
      userProfile: responseJson.data,
      isLoggedUser: false,
    })
  }

  handleMuteModel = (status: string) => {
    if (status == "no") {
      this.setState({ muteModal: false })
    } else {
      this.handleMuteAcoount(this.state.activeUser?.id.toString() as string)
    }
  }


  renderVerifyIcon = (val: { user_subscription: string | null }) => {

    switch (val.user_subscription) {
      case 'I am an individual':
        return <img src={verifyIcon} alt="" height={18} width={18} />;
      case 'I am a business':
        return <img src={verifyYellow} alt="" height={18} width={18} />;
      case 'I am a news organization':
        return <img src={verifyOrange} alt="" height={18} width={18} />;
      case null:
        return ""
    }
  }

  renderOwnershipIcon = (val: { user_ownership: boolean }) => {
    if (val.user_ownership) {
      return <img src={ownerShipIcon} alt="" height={18} width={18} />;
    }
  }


  handleShare = () => {
    this.setState({
      anchorEl: null
    },this.openShareDialog)
  }


  handleClick = (activeUser: User, postId: string) => {
    this.setState({
      activeUser: activeUser,
      reportingPostId: postId || ""
    })
  }

  handleMuteModelClose = () => {
    this.setState({ muteModal: false, })
  }

  muteConformation = () => {
    this.setState({ muteModal: true })
  }

  handleCloseFollowList=()=>{
    this.setState({
      openFollowerList:false,
    },this.getUserProfile)
  }

  handleGetFollowerList=(isFollow:boolean)=>{
    this.setState({
      openFollowerList:true,
      isFollowerList:isFollow
    })
  }

  showReportPost = () => {
    this.setState({
      showReportPostModal: true,
      reportPageLoading: true,
    }, this.getReportUserMainCategories)
  }

  handleReportUser=()=>{
    this.handleMenuClose()
    this.setState({
      showReportPostModal: true,
      isReportUser:true,
      reportPageLoading: true,
    },this.getReportUserMainCategories)
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({
      activeIndex: newValue,
      pagination: undefined,
      feed:[],
      fetchedPages:1
    },this.getPosts)
  }

 getPosts=()=>{
  switch(this.state.activeIndex){
    case  "Arrows":
      return this.getUserPosts()
    
    case "Replies":
      return this.getRepliedUserPosts()

    case "Likes":
      return this.getLikedUserPost()

    case "Subscriber":
      return this.getSubscribeUserPost()
  }
 }



  openMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleEditProfile=()=>{
    this.setState((prev)=>({
       editProfile: !prev.editProfile,
       anchorEl: null
    }))
  }

  handlePostActivityModel=(id?:string,postedById?:number)=>{  
    if(postedById == this.state.LoggedUser?.attributes.id){
      this.setState({showPostActivity:true ,ActivePostActivityId: id||""})
      this.getPostAcitivityData(id)
    }
  }

  handleNotificationPreference=()=>{
    this.setState((prev)=>({
       notificationPref:!prev.notificationPref
    }))
  }

  handleCloseHighlightedStory=()=>{
    this.setState({
      storyOpen:false
    })
  }
  
 
  deleteHighlight=()=>{
    this.getAccountHighlights()
    this.setState({
     storyOpen:false  
    })
  }

  EditHighlight=(highlight:IHighlights)=>{
      this.setState({
        storyOpen:false,
        selectedHighlight:highlight,
        EditHighlight:true,
        addHighlight:true
      })
  }



  openStory=(highlight:IHighlights)=>{
    this.setState({
      selectedHighlight:highlight,
      storyOpen:true,
    })
  }

  openCreateHighlightModal=()=>{
    this.setState({
      addHighlight:true
    })
  }

  closeAddHighlight=()=>{
    this.setState({
      addHighlight:false
    })
  }

  handlePinPost=()=>{
    this.setState({
      openPinpost:true
    })
 }

 handleClosePinPost=()=>{
  this.setState({
    openPinpost:false
  })
 }


 handleShowRepostModel = (postId: string) => {
  this.setState((prev) => {
    const selectedPost = prev.feed?.find(post => post.id === postId)
    return {
      ...prev, 
      showRepostModal: true,
      selectedPostComment: selectedPost
    }
  })
}

handleCloseRepostModal = () => {
  this.setState({showRepostModal: false})
}


handleQuote = () => {
  this.setState({showRepostModal: false,showQuote:true})
}

 getPinnedPost=(pinned:boolean)=>{
    this.setState({
      openPinpost:true,
      isPinned:pinned
    })
 }

 closeDeletePost=()=>{
  this.setState({
    openDeletPost:false
  })
 }

 openDeletePostModal=()=>{
    this.setState({
      openDeletPost:true
    })
 }

  handleUpdateCommentNumberOfPost = () => {
    const cloneFeeds = [...this.state.feed]
    const updateFeed = cloneFeeds.map((post) => {
      if (post.id === this.state.selectedPostComment?.id) {
        return {
          ...post,
          attributes: {
            ...post.attributes,
            total_comments: post.attributes.total_comments + 1
          }
        }
      } else {
        return post
      }
    })
    this.setState({feed: updateFeed})
  }


  handleCloseQuote = () => {
    this.setState({showQuote: false})
  }

  handleGoBackAfterSubmit = () => {
    this.setState({fetchedPages: 1, feed: [], pagination:undefined, showQuote: false})
    this.getUserPosts()
  }

  handleOnCloseCommentSetting=()=>{
    this.setState({
      openCommentSetting:false
    })
  }

  openCommentSetting=()=>{
    this.setState({
      openCommentSetting:true
    })
  }

  goBack=()=>{
    this.props.navigation.goBack()
  }

  handleArchive=()=>{
    this.setState({
      openArchives:true
    })
    this.handleMenuClose()
  }
  handleCloseArchives=()=>{
    this.setState({
      openArchives:false
    })
  }

  
  handleTailoredUser=()=>{
    const userId = this.props.navigation.getParam('navigationBarTitleText')
    if(this.state.userProfile?.attributes.is_tailored){
      this.handleRemoveUser(userId)
    }else{
      this.handleAdduser(userId)
    }
  }


  handleEditPlanSubscription = () =>{
    this.setState({isShowModalPlan: true});
  }

  handleResetPlanSubscription = () =>{
    this.setState({isShowModalPlan: false}, () => {
      this.getPlanLoggedUser();
    });
  }

  handleSetModalDeletePlan = (bool: boolean) => {
    this.setState({ isShowModalDeletePlan: bool }, () => {
      if (bool) {
        this.setState({ isShowModalPlan: false });
      } else {
        this.getPlanLoggedUser();
      }
    });
  }

  handleChangePricePlan = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    const validateValue = validateNumberField(value);
    if (!validateValue) return;
    this.setState({ planPrice: validateValue })
  }

  handleNextButtonPlan = async () => {
    if (this.state.userProfile?.attributes.stripe_account_connected === "notCreated") {
      await this.handleStripeConnect();
      return;
    }
    if(!this.state.userProfile?.attributes.subscription_id) {
      this.handleCreatePlan();
      return;
    }
    this.handleUpdatePlan();
  }

  handleCreatePlan = async () => {
    const token = await getStorageData("authToken");
    const headers = {
      token,
    };
    const formData = new FormData();
    formData.append("frequency", "monthly");
    formData.append("price", this.state.planPrice);
    const endpoint = "bx_block_custom_user_subs/subscriptions";
    this.apiCreatePlanCallId = sendAPIRequest(endpoint, {
      headers,
      body: formData,
      method: "POST",
    })
  }

  handleResponseCreatePlan = async (responseJson: TAny) => {
    if(!responseJson?.data) {
      toast.error("Create subscription failed, please try again later");
      return;
    }
    this.setState({isShowModalPlan: false}, async () => {
      notify("Plan created")
      await this.getUserProfile();
    })
  }

  getPlanLoggedUser = async () => {
    const token = await getStorageData("authToken");
    const headers = {
      token,
    };
    if(!this.state.userProfile?.attributes.subscription_id) this.setState({planPrice: ""})
    const endpoint = `bx_block_custom_user_subs/subscriptions/${this.state.userProfile?.attributes.subscription_id}`;
    this.apiGetPlanCallId = sendAPIRequest(endpoint, {
      headers,
      method: "GET",
    })
  }

  handleResponseGetPlan = async (responseJson: TAny) => {
    this.setState({planPrice: responseJson?.data.attributes.price})
  }

  handleUpdatePlan = async () => {
    const token = await getStorageData("authToken");
    const headers = {
      token,
    };
    const formData = new FormData();
    formData.append("frequency", "monthly");
    formData.append("price", this.state.planPrice);
    const endpoint = `bx_block_custom_user_subs/subscriptions/${this.state.userProfile?.attributes.subscription_id}`;
    this.apiUpdatePlanCallId = sendAPIRequest(endpoint, {
      headers,
      body: formData,
      method: "PUT",
    })
  }

  handleResponseUpdatePlan = async (responseJson: TAny) => {
    if(!responseJson?.data) {
      toast.error("Update subscription failed, please try again later");
      return;
    }
    this.setState({isShowModalPlan: false}, async () => {
      notify("Plan Edited")
      await this.getUserProfile();
    })
  }

  handleDeletePlan = async () => {
    const token = await getStorageData("authToken");
    const headers = {
      token,
    };
    const endpoint = `bx_block_custom_user_subs/subscriptions/${this.state.userProfile?.attributes.subscription_id}`;
    this.apiDeletePlanCallId = sendAPIRequest(endpoint, {
      headers,
      method: "DELETE",
    })
  }

  handleResponseDeletePlan = async (responseJson: TAny) => {
    if(!responseJson?.messages) {
      toast.error("Remove subscription failed, please try again later");
      return;
    }
    this.setState({isShowModalDeletePlan: false}, async () => {
      notify("Plan deleted")
      await this.getUserProfile();
    })
  }

  handleStripeConnect = async () => {
    const token = await getStorageData("authToken");
    const headers = {
      token,
    };
    const endpoint = `bx_block_stripe_integration/stripe/connect`;
    this.apiStripeConnectCallId = sendAPIRequest(endpoint, {
      headers,
      method: "GET",
    })
  }

  handleResponseStripeConnect = (responseJson: TAny) => {
    if(!responseJson?.url) {
      toast.error("Cannot create a Stripe Connect account.");
      return;
    }
    const { url } = responseJson;
    const { location: locationUrl} = window;
    locationUrl.href = url;
  }

  goBuySubscription = () =>{
    const userId =this.props.navigation.getParam('navigationBarTitleText')
    this.props?.navigation?.navigate('MySubscription',{id:userId});
   
  }

  onCloseShare=()=>{
   this.setState({
    openShare:false
   })
  }

  openShareDialog=()=>{
    const userId =this.props.navigation.getParam('navigationBarTitleText')
    const Url = `${window.location.origin}/Profile/${userId}`
    this.setState({
      openShare:true,
      shareProfileUrl:Url
    })
  }

  handleNavigateToChatConversation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),"Chat"
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      profileUid: this.state.userProfile?.attributes.cometchat_uid
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage("NavigationMessageFromProfile", message)
  }

  handleToggleRemoveAddUserCustomList = async (userId: number, isAdd: boolean) => {
		const token = await getStorageData("authToken")
		const url = 'account_block/tailored_accounts'
		const formdata = new FormData();
		formdata.append("member_id", userId.toString())
	
		const postList = [...this.state.feed];
		const updatepostList = postList.map((postItem) => {
		  let postBy = postItem.attributes.post_by;
		  if (postItem.attributes.post_by.id == userId) {
			postBy = {
			  ...postItem.attributes.post_by,
			  is_tailored: isAdd,
			};
		  }
		  
		  return {
			...postItem,
			attributes: {
			  ...postItem.attributes,
			  post_by: postBy,
			},
		  };
		});
	
		this.setState({ feed: updatepostList });
	
		const apiId = sendAPIRequest(url, {
		  method: isAdd ? "POST" : "DELETE",
		  headers: { 
        token: token
      },
		  body: formdata,
		});
	
		this.toggleAddRemoveUserCustomListOnPostId = {id: apiId, userId }
	};

  handleShowUnfollowConfirmModal = () => {
    this.setState({showUnfollowConfirmModal: true})
  }

  handleCloseUnfollowConfirmModal = () => {
    this.setState({showUnfollowConfirmModal: false})
  }
  // Customizable Area End
}
