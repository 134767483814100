import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { notification, vibratePhone ,notificationLight,vibratePhoneLight} from './assets'
const deatils = [{ id: 1, title: 'Muted notification', description: 'Choose the notifications you’d like to see — and those you don’t.', iconLight: notification, iconDark: notificationLight },
{ id: 2, title: 'Preferences', description: 'Select your preferences by notification type.', iconLight: vibratePhone, iconDark: vibratePhoneLight }]
const mentionsList = [{ id: 1, title: 'Custom List', isClick: false, key: "custom_list" },
{ id: 2, title: 'From anyone', isClick: false, key: "from_anyone" },
{ id: 3, title: 'Off', isClick: false, key: "off" }
]
const personPreferences=[{ id: 1, title: 'All Posts', description: 'Get notification for all of this account’s Posts.',isClick:false},
{ id: 2, title: 'All Posts & Replies', description: 'Get notification for all of this account’s Posts.',isClick:false},
{ id: 3, title: 'Only Live video', description: 'Get notification for all of this account’s Posts.',isClick:false},
{ id: 0, title: 'Off', description: 'Get notification for all of this account’s Posts.',isClick:false}]
import {getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?:boolean;
  classes:{[key:string]:string}
  // Customizable Area End
}

type SETTING_POSTS_KEY = "mensions_and_replies" | "repost" | "likes" | "photo_tags";
export type SETTING_POSTS_VALUE = "custom_list" | "from_anyone" | "off";

interface S {
  // Customizable Area Start
  settingsDetails:{id:number,title:string,description:string,iconLight:string,iconDark:string}[],
  activeOption:string,
  muteNotification:{id:number,title:string,isClick:boolean, label: string}[],
  preferenceDetails:{id:number,title:string}[],
  photoTag:boolean,
  contacts:{id:number,title:string,isClick:boolean}[],
  recommendations:{id:number,title:string,isClick:boolean}[],
  message:boolean,
  news:{id:number,title:string,isClick:boolean}[],
  add:boolean,
  open:boolean,
  activeModalDetails:string,
  mentionsList:{id:number,title:string,isClick:boolean}[],
  unreadNotification:boolean,
  submitNotification:{},
  updateNotificationStatus:string,
  personPreferences:{id:number,title:string,isClick:boolean,description:string}[],
  token:string,
  showUsers:{id:number,fullName:string,userName:string,profile:string}[],
  userId:number,
  preferenceId:number,
  updatedPreferenceStatus:string,
  activeChoice:{},
  activeFollowers:number,
  activeUserName:string,
  emailNotification:boolean,
  related_to_you_and_your_posts: {
    mensions_and_replies: SETTING_POSTS_VALUE,
    repost: SETTING_POSTS_VALUE,
    likes: SETTING_POSTS_VALUE,
    photo_tags: boolean,
  },
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SettingNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  followersListCallId:string=''
  muteNotificationApi:string=''
  updateNotificationApi:string=''
  updatePreferenceApi:string=''
  updatePreferApi:string=''
  muteNotificationPeferApi:string=''
  updateNotifySetting:string=''
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
    settingsDetails:deatils,
    activeOption:'',
    muteNotification:[],
    preferenceDetails:[],
    photoTag:false,
    contacts:[],
    recommendations:[],
    message:false,
    news:[],
    add:false,
    open:false,
    activeModalDetails:'',
    mentionsList:mentionsList,
    unreadNotification:false,
    submitNotification:{},
    updateNotificationStatus:'',
    personPreferences:personPreferences,
    token:'',
    showUsers:[],
    userId:0,
    preferenceId:0,
    updatedPreferenceStatus:'',
    activeChoice:{},
    activeFollowers:0,
    activeUserName:'',
    emailNotification:false,
    related_to_you_and_your_posts: {
      mensions_and_replies: "off",
      repost: "off",
      likes: "off",
      photo_tags: false,
    }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.showMuteNotifications(message)
    this.updateNotificationsApi(message)
    this.updatePreferencesApi(message)
    this.updatePrefersApi(message)
    this.muteNotificationsPeferApi(message)
    this.changeNotifiSetting(message)
    // Customizable Area End
  }

  // Customizable Area Start

async componentDidMount(){
  const token=await getStorageData("authToken") 
  this.setState({token:token})  
 } 

 changeNotifiSetting=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.updateNotifySetting) {
       this.setState({open:false,activeOption:'push notification'})

      }
    } else{
      this.setState({updateNotificationStatus:'invalid token'})
    }
  }

 }
  
  showMuteNotifications=(message:Message)=>{
    const labelNotifcation: any = {
      "you_dont_follow": "You don't follow",
      "who_dont_follow_you": "Who don’t follow you",
      "with_new_account": "With a new account",
      "have_default_profile": "Who have a default profile photo",
      "not_confirm_email": "Who haven’t confirmed their email",
      "not_confirm_phone": "Who haven’t confirmed their phone number",
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.muteNotificationApi) {
          const convertedArray = Object.keys(responseJson.data.attributes.muted_notifications).map((key, index) => ({
            title: key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
            label: labelNotifcation[key] || "",
            isClick: responseJson.data.attributes.muted_notifications[key],
            id: index + 1
          }));
          this.setState({
            muteNotification: convertedArray,
            unreadNotification: responseJson.data.attributes.unread_notification_count,
            emailNotification:responseJson.data.attributes.email_notification
          })

        }
      } else{
        this.setState({updateNotificationStatus:'invalid token'})
      }
    }
  }

  updatePrefersApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.updatePreferApi) {
          this.setState({updatedPreferenceStatus:responseJson.messages[0],open:false})
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else{
        this.setState({updatedPreferenceStatus:'invalid token'})
      }
    }
  }

  updateNotificationsApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.updateNotificationApi) {
          this.setState({updateNotificationStatus:'updated sucessfully'})
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else{
        this.setState({updateNotificationStatus:'invalid token'})
      }
    }
  }

  updatePreferencesApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.updatePreferenceApi) {
          const result = responseJson.following_accounts?.data.map((item:{attributes:{id:number,full_name:string,user_name:string,profile_photo:string|null}}) =>
          ({
            id: item.attributes.id, fullName: item.attributes.full_name,
            userName: item.attributes.user_name, profile: item.attributes.profile_photo
          }))
          this.setState({showUsers:result,activeFollowers:result.length})
          this.parseApiCatchErrorResponse(errorReponse);
          }
      } else{
        this.setState({updateNotificationStatus:'invalid token'})
      }
    }

  }

  muteNotificationsPeferApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.muteNotificationPeferApi) {
          const recommendationArray = Object.keys(responseJson.data.attributes.recommendations_from_arrows).map((key, index) => ({
            title: key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
            isClick: responseJson.data.attributes.recommendations_from_arrows[key],
            id: index + 1
          }));
          const newsArray = Object.keys(responseJson.data.attributes.news_from_arrows).map((key, index) => ({
            title: key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
            isClick: responseJson.data.attributes.news_from_arrows[key],
            id: index + 1
          }));

          const contactArray = Object.keys(responseJson.data.attributes.followers_and_contacts).map((key, index) => ({
            title: key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
            isClick: responseJson.data.attributes.followers_and_contacts[key],
            id: index + 1
          }));


          const preferenceDetails = Object.entries(responseJson.data.attributes.related_to_you_and_your_posts)
            .filter(([key, value]) => typeof value !== 'boolean')
            .map(([key, value], index) => ({
              id: index + 1,
              title: key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
            })); 

          this.setState({
            unreadNotification: responseJson.data.attributes.unread_notification_count,
            emailNotification:responseJson.data.attributes.email_notification,
            photoTag: responseJson.data.attributes.related_to_you_and_your_posts.photo_tags,
            message: responseJson.data.attributes.direct_messages.direct_messages,
            recommendations: recommendationArray,
            preferenceDetails:preferenceDetails,
            news: newsArray,
            contacts: contactArray,
            related_to_you_and_your_posts: responseJson.data.attributes.related_to_you_and_your_posts,
          })

        }
      } else{
        this.setState({updateNotificationStatus:'invalid token'})
      }
    }

  }

  navigateToOption=(title:string)=>{
    this.setState({activeOption:title})
    const header = {
      token:this.state.token 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.muteNotificationApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.muteNotificationApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectOption = (id: number, title: string, click: boolean) => {
    const resultText = title.toLowerCase().replace(/ /g, '_')
    const { submitNotification } = this.state
    const updatedNotification = {
      ...submitNotification,
      [resultText]: !click
    };
    this.setState({ submitNotification: updatedNotification });

    const result = this.state.muteNotification.map((item) => {
      if (item.id === id) {
        return { ...item, isClick: !item.isClick }
      }
      return { ...item, isClick: item.isClick }
    })
    this.setState({ muteNotification: result },()=>this.updateNotofications(this.state.submitNotification))
  }

  gotoNotification=()=>{
    this.setState({activeOption:''})
  }

  gotoPreference=()=>{
    this.setState({activeOption:''})
  }

  gotoPreferenceScreen=()=>{
    this.setState({activeOption:'Preferences'})
  }

  gotoPushNotification=()=>{
    this.setState({activeOption:'push notification'})
  }

  navigateToPushNotification=()=>{
    this.setState({activeOption:'push notification'})
    const header = {
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.muteNotificationPeferApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.muteNotificationApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlePhotoTag=()=>{
    this.setState({photoTag:!this.state.photoTag},()=>this.updateNotofications({photo_tags:this.state.photoTag}))
  }

  handleContacts=(id:number,click:boolean,title:string)=>{
  const result=this.state.contacts.map((item)=>{
    if(item.id===id){
      return {...item,isClick:!item.isClick}
    }
    return {...item,isClick:item.isClick}
  })
  this.setState({contacts:result},()=>this.updateNotofications({[title.toLowerCase().replace(/ /g, '_')]:!click}))
  }

  handleMessage=()=>{
  this.setState({message:!this.state.message},()=>this.updateNotofications({direct_messages:this.state.message}))
  }

  handleRecommendations=(id:number,title:string,click:boolean)=>{
  const result=this.state.recommendations.map((item)=>{
    if(item.id===id){
      return {...item,isClick:!item.isClick}
    }
    return {...item,isClick:item.isClick}
  })  
  this.setState({recommendations:result},()=>this.updateNotofications({[title.toLowerCase().replace(/ /g, '_')]:!click}))
  }

  handleMentions=(id:number,title:string,click:boolean)=>{
      const object = {[title.toLowerCase().replace(/ /g, '_')]:!click}
      this.setState({activeChoice:object})
    const result=this.state.mentionsList.map((item)=>{
      if(item.id===id){
        return {...item,isClick:!item.isClick}
      }
      return {...item,isClick:false}
    })  
    this.setState({mentionsList:result})
    }

  setNews=(id:number,title:string,click:boolean)=>{
  const result= this.state.news.map((item)=>{
    if(item.id===id){
      return {...item,isClick:!item.isClick}
    }
    return {...item,isClick:item.isClick}
  })
  this.setState({news:result},()=>this.updateNotofications({[title.toLowerCase().replace(/ /g, '_')]:!click}))
  }

  setAdds=()=>{
   this.setState({add:!this.state.add},()=>this.updateNotofications({}))
  }

  navigateToPosts=()=>{
    this.setState({activeOption:'posts'})
    const header = {
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePreferenceApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePeferenceEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openMentionsModal=(option:string)=>{
    this.setState({open:true,activeOption:'posts',activeModalDetails:option,mentionsList:mentionsList})
  }
  handleClose=()=>{
    this.setState({open:false,activeOption:'push notification'})
  }

  handlePostsSettingData = (keyValue: SETTING_POSTS_KEY) => {
    const dataValue = this.state.related_to_you_and_your_posts[keyValue];
    return mentionsList.map(value => {
      if(value.key === dataValue) {
        return {...value, isClick: true}
      }
      return {...value};
    })
  }

  handleClickPostsSetting = (keyValue: SETTING_POSTS_KEY, value: SETTING_POSTS_VALUE) => {
    const newDataValue = {...this.state.related_to_you_and_your_posts, [keyValue]: value};
    this.setState({related_to_you_and_your_posts: newDataValue});
  }

  showReactions = () => {
    const mentionsLists = [{ id: 1, title: 'Your own messages', isClick: false },
    { id: 2, title: 'Everyone’s messages', isClick: false },
    { id: 3, title: 'Off', isClick: false }
    ]

    this.setState({ open: true, activeOption: 'posts', activeModalDetails: 'Message reactions', mentionsList: mentionsLists })
  }

  updateNotofications=(updateObject:{})=>{

    if(Object.keys(updateObject).length!==0){
    const header = {
      token:this.state.token,
      'Content-Type': configJSON.exampleApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateNotificationApi = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notifyUpdateApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(
        updateObject
      )
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPatchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    

  }

  openUserModal=(id:number,name:string)=>{
    this.setState({userId:id})
    this.setState({ open: true, activeModalDetails: 'Persons',activeUserName:name })

  }

  handlepersonPrefer=(id:number)=>{
    this.setState({preferenceId:id})
    const result= this.state.personPreferences.map((item)=>{
      if(item.id===id){
        return {...item,isClick:!item.isClick}
      }
      return {...item,isClick:false}
    })
    this.setState({personPreferences:result})
  }

  updatePreferences=()=>{
    const header = {
      token:this.state.token
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePreferApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatePreferApiEndpoint}?notifier_id=${this.state.userId}&preference=${this.state.preferenceId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPatchMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage); 
    
  }

  updateNotificationSetting=(bodyData?: any)=>{
    const header = {
      token:this.state.token,
      'Content-Type': configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateNotifySetting = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notifyUpdateApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData ?? this.state.activeChoice)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  submitMentionReplies=()=>{
    const body =  {
      "mensions_and_replies": this.state.related_to_you_and_your_posts.mensions_and_replies,
    }
    this.updateNotificationSetting(body)
   
  }

  submitLikes=()=>{
    const body =  {
      "likes": this.state.related_to_you_and_your_posts.likes,
    }
    this.updateNotificationSetting(body)
  }

  submitRepost=()=>{
    const body =  {
      "repost": this.state.related_to_you_and_your_posts.repost,
    }
    this.updateNotificationSetting(body)
  }

  submitMessageReactions=()=>{
    this.updateNotificationSetting()
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({unreadNotification:event.target.checked},()=>this.updateNotofications({unread_notification_count:this.state.unreadNotification}))
 };

 handleChangeEmail=(event: React.ChangeEvent<HTMLInputElement>)=>{
   this.setState({emailNotification:event.target.checked},()=>this.updateNotofications({email_notification:this.state.emailNotification}))
 }

 closeModal=()=>{
  this.setState({open:false,activeOption:'push notification'})
  this.navigateToPushNotification();
 }

 closeModalPerson=()=>{
  this.setState({open:false})
 }

 navigateToSettings=()=>{
  this.props.navigation('Settings')
 }

  // Customizable Area End
}
