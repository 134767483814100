import { Box, styled, TextField, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { rightBlackIcon, rightIcon } from "../../blocks/groups/src/assets";
import { TTabGroupScreen } from "../../blocks/groups/src/GroupsController";
import clsx from "clsx";

interface IPropsGroupsSettings {
    checked?: boolean;
    scope?: string;
    isEditGroup: boolean;
    isCanEdit: boolean;
    isCreateGroup?: boolean;
    isOpenModalSubscription: boolean;
    groupDescriptionValue: string;
    onToggleModalSubscription: (isOpenModalDisappearing: boolean) => void;
    onChangeGroupScreen?: (tab: TTabGroupScreen) => void;
    onChangeGroupDescription: (value: string) => void;
    handleCreateGroupPlan?: () => void;
}

function GroupsSettings({
    checked = false,
    scope = "",
    isEditGroup = false,
    isCanEdit = false,
    isCreateGroup = false,
    isOpenModalSubscription,
    groupDescriptionValue = "",
    onToggleModalSubscription,
    onChangeGroupScreen,
    onChangeGroupDescription,
}: IPropsGroupsSettings) {
    const [groupDescription, setGroupDescription] = useState("");

    useEffect(() => {
        setGroupDescription(groupDescriptionValue);
    }, [groupDescriptionValue])

    const handleCheckConditionPermission = () => {
        if (scope !== "admin") {
            if (!isCanEdit || !isEditGroup) {
                return false;
            }
            if (isEditGroup && scope !== "admin") {
                return false;
            }
        }
        return true;
    }

    const renderByPermission = () => {
        const isHasPermission = handleCheckConditionPermission();
        if (!isHasPermission) return <Fragment />
        return <Fragment>
            <Box
                className={`settingWrapper`}
                data-test-id="groupPermissionBtn"
                onClick={() =>
                    onChangeGroupScreen && onChangeGroupScreen("group_permission")
                }
            >
                <Box className={`settingItem`}>
                    <Typography
                        className={`settingTitle ${checked && "settingTitleLight"}`}
                    >
                        Group permission
                    </Typography>
                </Box>
                <img src={checked ? rightBlackIcon : rightIcon} />
            </Box>
        </Fragment>
    }

    return (
        <GroupsSettingsStyled checked={checked?.toString()} className="groupsSettingsStyled">
            <Box
                className={`settingWrapper settingDescription`}
            >
                <Box className={`settingItem`}>

                    <Typography
                        className={`settingTitle ${checked && "settingTitleLight"}`}
                    >
                        Description
                    </Typography>
                    {
                        isEditGroup ? <TextField
                            className="descriptionGroup"
                            variant="outlined"
                            value={groupDescription}
                            onChange={(e) => {
                                const { value } = e.target;
                                setGroupDescription(value);
                                onChangeGroupDescription && onChangeGroupDescription(value);
                            }}
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={4}
                            InputProps={{
                                style: { borderRadius: "10px", maxHeight: "120px" },
                            }}
                            placeholder="Enter your description here."
                        />
                            : <Box className="descriptionGroup-show" >
                                <Typography
                                    component="p"
                                    className={`${clsx(checked && "lightThemePrimaryTextColor")}`}
                                >
                                    {groupDescription}
                                </Typography>
                            </Box>
                    }
                </Box>
            </Box>
            {
                !isCreateGroup && <Box
                    className={`settingWrapper`}
                    onClick={() => {
                        onChangeGroupScreen && onChangeGroupScreen("gallery")
                    }}
                >
                    <Box className={`settingItem`}>
                        <Typography
                            className={`settingTitle ${checked && "settingTitleLight"}`}
                        >
                            Gallery
                        </Typography>
                    </Box>
                    <img src={checked ? rightBlackIcon : rightIcon} />
                </Box>
            }
            {
                renderByPermission()
            }
            <Box
                className={`settingWrapper ${clsx((!isCanEdit && scope !== "admin") && "cursorDisable")}`}
                data-test-id="groupSubscriptionBtn"
                onClick={() => {
                    onToggleModalSubscription(isOpenModalSubscription)
                }}
            >
                <Box className={`settingItem`}>
                    <Typography
                        className={`settingTitle ${checked && "settingTitleLight"}`}
                    >
                        Add Subscription (Optional)
                    </Typography>
                </Box>
                <img src={checked ? rightBlackIcon : rightIcon} />
            </Box>
            <Box
                className={`settingWrapper ${clsx((!isCanEdit && scope !== "admin") && "cursorDisable")}`}
                onClick={() => {
                }}
            >
                <Box className={`settingItem`}>
                    <Typography
                        className={`settingTitle ${checked && "settingTitleLight"}`}
                    >
                        Member requests
                    </Typography>
                    {/* <Typography className={`settingDesc`}>
                        0 Pending requests
                    </Typography> */}
                </Box>
                <img src={checked ? rightBlackIcon : rightIcon} />
            </Box>
        </GroupsSettingsStyled>
    );
}

const GroupsSettingsStyled = styled(Box)(({ checked }: { checked: string }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    borderBottom: "2px solid #222222",
    paddingBottom: "8px",
    "& > *": {
        margin: "0 20px",
    },
    "& .cursorDisable": {
        cursor: "not-allowed!important"
    },
    "& .lightThemePrimaryTextColor": {
        color: "#222222!important"
    },
    "& .settingWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        height: 45,
        "& .settingItem": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            "& .settingTitle": {
                color: "#FFF",
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Manrope",
            },
            "& .settingTitleLight": {
                color: "#222222",
            },
            "& .settingDesc": {
                fontSize: "12px",
                fontFamily: "Manrope",
                fontWeight: 500,
                color: "#6B6B6B",
                marginTop: 5,
            },
        },
    },
    "& .settingWrapper.settingDescription": {
        height: "auto",
        marginBottom: "11px",
        "& .settingItem": {
            width: "100%",
            "& .descriptionGroup": {
                marginTop: "8px",
                '& .MuiInputBase-input::placeholder': {
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: "#6B6B6B",
                },
                "& .MuiOutlinedInput-input": {
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: checked === "true" ? "#222222" : "#FFFFFF"
                },
            },
            "& .descriptionGroup-show": {
                cursor: "context-menu",
                padding: "10px 16px 20px",
                width: "calc(100% - 24px)",
                "& p": {
                    color: "#FFFFFF",
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontWeight: 500,
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    maxHeight: "300px",
                    overflowY: "auto"
                }
            }
        }
    }
}));

export default GroupsSettings;
