import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
const uuId=require('device-uuid')
import  jwt_decode  from "jwt-decode";
import { setStorageData,getStorageData,removeStorageData } from "../../../framework/src/Utilities";
import { ReactNode } from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import codes from "country-calling-code";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from "react";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
interface CredentialResponse {
  credential?: string;
  select_by?: 'auto' | 'user' | 'user_1tap' | 'user_2tap' | 'btn' | 'btn_confirm' | 'brn_add_session' | 'btn_confirm_add_session';
  clientId?: string;
}

export interface IUserAttributes {
  id: number;
  full_name: string;
  user_name: string;
  email: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  gender: string | null;
  profile_photo: string | null;
  cover_photo: string | null;
}

export interface IToken {
  token: string;
  refresh_token: string;
}

export interface IUserResponse {
  data: {
    id: string;
    type: string;
    attributes: IUserAttributes;
  };
  meta: IToken;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string}
  children?:ReactNode
  checked:boolean
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  checkboxError: string;
  openModal: boolean;
  privacyModel: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: string[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  name:string;
  phoneNumber:string;
  dateOfBirth:string;
  errors:{nameError:string,phoneNumberError:string,dobError:string,emailError:string};
  showSuggestion:boolean;
  userEmail:string;
  checked:boolean;
  apiError:string;
  formError:string;
  disable:boolean,
  codesFilter: Array<{
    isoCode2: string;
    countryCodes: Array<string>;
    country:string
  }>
  countryCode:string
  showCalendar:boolean
  anchorEl:null | HTMLElement
  calendarDate:string | Date
  userGoogle:CredentialResponse
  profileGoogle:{firstName:string,lastName:string,email:string,photo:string}
  error:string
  loginPageLoading:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: {password_validation_regexp:'',password_validation_rules:'',email_validation_regexp:''}[];
  passwordReg: RegExp;
  emailReg: RegExp;
  imgPasswordVisible: string;
  imgPasswordInVisible: string;
  labelHeader: string;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  createAccountApiCallId: string='';
  validationApiCallId: string = "";
  accountCreationApiCallId:string="";
  getGoogleUserCallId:string="";
  googleAccountApiCallId:string="";
  timeout:ReturnType<typeof setTimeout>|null=null;
  loggedInApiCallIdSignup:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      checkboxError: "",
      privacyModel: false,
      openModal: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      name:'',
      phoneNumber:'',
      dateOfBirth:"",
      errors:{nameError:'',phoneNumberError:'',dobError:'',emailError:''},
      showSuggestion:true,
      userEmail:'',
      checked:false,
      apiError:'',
      formError:'',
      disable:true,
      codesFilter:codes,
      countryCode:"1",
      showCalendar:false,
      anchorEl:null,
      calendarDate:"",
      userGoogle:{credential:'',clientId:'',select_by:'auto'},
      profileGoogle:{firstName:'',lastName:'',email:'',photo:''},
      error:'',
      loginPageLoading:false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.validationApi(message)

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    } 

    this.createAccountApi(message)
    this.getProfileGoogle(message)
    this.getUserApiSignup(message)
 
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
   const theme= await getStorageData('theme')
   const sortedArray = this.state.codesFilter;
   sortedArray.sort((a,b)=>a.country.localeCompare(b.country))
   this.setState({checked:JSON.parse(theme), codesFilter:sortedArray}) 
    }

  getLoggedInUserSignup=(responseJson:IUserResponse)=>{
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token: responseJson.meta.token,
    };

    const getMssage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loggedInApiCallIdSignup = getMssage.messageId;

    getMssage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedInEndPoint
    );

    getMssage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMssage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getMssage.id, getMssage);

  }

  getUserApiSignup=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJsonSuccess = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJsonSuccess && !responseJsonSuccess.errors) {
        if (apiRequestCallId === this.loggedInApiCallIdSignup) {
          this.handleRedirectionFlow(responseJsonSuccess.data.attributes.signup_state)
          
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        this.setState({ apiError: responseJsonSuccess.errors[0] })
      }
    }
  }



  validationApi=async(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

     

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const message: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            message.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(message);
          } else {
  
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

  }

  createAccountApi=async(message:Message)=>{

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.setState({loginPageLoading:false})

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.accountCreationApiCallId) {
          removeStorageData('screen')
          this.getResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        this.setState({ apiError: responseJson.errors[0] })
      }
    }
  }

  getProfileGoogle=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.googleAccountApiCallId) {
          setStorageData('authToken',responseJson.meta.token)
          this.handleMultiUser(responseJson)
          this.handleRedirectionFlow(responseJson.data.attributes.signup_state)
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        this.setState({ apiError: responseJson.errors[0] })
      }
    }

  }

  handleRedirectionFlow=(screen:string)=>{

    switch (screen) {
      case 'date_of_birth':
        this.navigateToRoutes("DateOfBirth");
        break;
      case 'user_name':
          this.navigateToRoutes("OTPInputAuth", 'user');
          break;
  
      case 'choose_profile':
        this.navigateToRoutes("OTPInputAuth",'profile');
        break;
  
      case 'select_category':
          this.navigateToRoutes("Categories");
        break;
  
      case 'select_sub_category':
        this.navigateToRoutes("Categories",'sub');
        break;
  
      case 'choose_subscription':
          this.navigateToRoutes("Customisableusersubscriptions");
          break;
  
      case 'become_an_owner':
          this.navigateToRoutes("BecomeOwner");
        break;
  
      case 'connect_social_account':
        this.navigateToRoutes("Followers");
        break;
  
      case 'follow_account':
          this.navigateToRoutes("Followers",'follow');
          break;
      case 'completed':
            this.navigateToRoutes("ActivityFeed");
          break;    
  
      default:
        break;
    }

  }

  navigateToRoutes=(route:string,profile?:string)=>{
    const messageRequest: Message = new Message(getName(MessageEnum.NavigationMessage));
    messageRequest.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messageRequest.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    const raiseMessages: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessages.addData(getName(MessageEnum.SessionResponseData), {
      active:profile,
    });
    messageRequest.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessages);
    this.send(messageRequest);
  }
  
  handleMultiUser = async (responseJson: IUserResponse) => {
    let multiUserObject: Array<{
      refresh_token: string | undefined;
      user_id: string;
      token: string | undefined;
      profile_photo: string;
      username: string;
      fullName: string;
    }> = [];
    const sendDetails = {
      refresh_token: responseJson?.meta && responseJson?.meta?.refresh_token,
      user_id: responseJson.data.id,
      token: responseJson?.meta && responseJson?.meta?.token,
      profile_photo: responseJson.data.attributes.profile_photo as string,
      username: responseJson.data.attributes.user_name,
      fullName: responseJson.data.attributes.full_name,
    };
    setStorageData("selectedUser", JSON.stringify(sendDetails));
    const usersData = await getStorageData("multi_user", true);

    multiUserObject = usersData ?? [];
    if (multiUserObject.length > 0) {
      const findUsers = multiUserObject.find(
        (item) => item.user_id === sendDetails.user_id
      );
      if (findUsers) {
        const multi_user = multiUserObject.map((item) => {
          if (item.user_id === sendDetails.user_id) {
            return {...item,username:sendDetails.username,profile_photo:sendDetails.username};
          } else {
            return item;
          }
        });
        setStorageData("multi_user", JSON.stringify(multi_user));
      } else {
        multiUserObject.push(sendDetails);
        setStorageData("multi_user", JSON.stringify(multiUserObject));
      }
    } else {
      multiUserObject.push(sendDetails);
      setStorageData("multi_user", JSON.stringify(multiUserObject));
    }
  };

  navigateToUserName=(responseJson:IUserResponse)=>{
    const msgRequest: Message = new Message(getName(MessageEnum.NavigationMessage));
    msgRequest.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgRequest.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OTPInputAuth"
    );
    const raiseMsg: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMsg.addData(getName(MessageEnum.SessionResponseData), {screen:3,response:responseJson});
    msgRequest.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMsg);
    this.send(msgRequest);
  }


  getResponse=async(responseJson:{data:{},meta:{token:string}})=>{
    if (responseJson.data) {
      const authData = {
        name: this.state.name,
        dateOfBirth: this.state.dateOfBirth,
        token: responseJson.meta.token,
        phoneNumber: this.state.showSuggestion ? `${this.state.countryCode}${this.state.phoneNumber}` : '',
        email: this.state.userEmail.toLowerCase()
      }

      await setStorageData('userInfo', (JSON.stringify(authData)))

      this.handleNavigation()
      this.setState({ name: '', userEmail: '', phoneNumber: '', dateOfBirth: '' })

    }
    
  }

  goToPrivacyPolicy() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToTermsAndCondition() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  isStringNullOrBlank(string: string) {
    return string === null || string.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    let phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const apiData = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: apiData,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleSelectCountryCode = (e: any) => {    
    this.setState({ countryCode: e.target.value[0],phoneNumber:'',errors:{...this.state.errors,phoneNumberError:''} },()=>{
      const country:any = this.state.codesFilter.filter((countryCode) => {
        return countryCode.countryCodes[0] == this.state.countryCode
      });       
  
      const mobileNumberValidator = parsePhoneNumberFromString(`+${this.state.countryCode} ${this.state.phoneNumber}`,country[0].isoCode2);
      if (!(mobileNumberValidator ? mobileNumberValidator?.isValid() : false)&&(this.state.phoneNumber.length > 0 && this.state.formError)){
        this.setState((prevState) => ({
          errors: { ...prevState.errors, phoneNumberError: configJSON.enterValidNumber },formError:configJSON.enterValidNumber
        }));
      }
    });
  };

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos:{onChangeText:(text:string)=>void,value:string} = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      this.txtInputEmailPrpos.value = text;
    },
    value: ""
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps:{onChangeText:(text:string)=>void,value:string} = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });
      this.txtPhoneNumberProps.value = text;
    },
    value: ""
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos: {onChangeText:(text:string)=>void,value:string}= {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });
      this.txtInputLastNamePrpos.value = text;
    },
    value: ""
  };

  txtInputFirstNamePrpos:{onChangeText:(text:string)=>void,value:string} = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
      this.txtInputFirstNamePrpos.value = text;
    },
    value: ""
  };

  txtInputConfirmPasswordProps:{onChangeText:(text:string)=>void,value:string,secureTextEntry:boolean} = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });
      this.txtInputConfirmPasswordProps.value = text;
    },
    value:'',
    secureTextEntry: true
  };

  txtInputPasswordProps:{onChangeText:(text:string)=>void,value:string,secureTextEntry:boolean} = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
    value: ""
  };


  handleNavigation=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OTPInputAuth"
    );
    this.send(message);
}

getAPiData=(phoneNUmberObject:any,emailObject:any)=>{
  return {
    attributes: this.state.showSuggestion ?  emailObject : phoneNUmberObject 
  };
}


getOtp=(event:React.FormEvent<HTMLFormElement>)=>{
    event.preventDefault()
    const countryFilter:any = this.state.codesFilter.filter((countryCode) => {
      return countryCode.countryCodes[0] == this.state.countryCode
    });       
    const mobileNumberValidation = parsePhoneNumberFromString(`+${this.state.countryCode} ${this.state.phoneNumber}`,countryFilter[0].isoCode2);
   
    const emailRegex=configJSON.emailRegex
    const parts = this.state.dateOfBirth.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const currentDay = parseInt(parts[2], 10);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentDateOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1
    
    
    if (this.state.name.trim()===''){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, nameError: configJSON.nameError }
      }));
     }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, nameError: '' }
      }));
    }

    if (this.state.phoneNumber===''){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, phoneNumberError:configJSON.phoneError}
      }));
    }
    else if (!(mobileNumberValidation ? mobileNumberValidation?.isValid() : false)){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, phoneNumberError:configJSON.enterValidNumber}
      }));
    }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, phoneNumberError: ''}
      }));
    }

    if (this.state.userEmail===''){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, emailError:configJSON.enterMail }
      }));
    }

    else if (!emailRegex.test(this.state.userEmail.toLowerCase())){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, emailError: configJSON.enterValidEmail }
      }));
    }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, emailError: '' }
      }));
    } 

    this.validateDob(this.state.dateOfBirth)

   if ((this.state.name!=='' && this.state.phoneNumber!=='' && mobileNumberValidation?.isValid() && this.state.dateOfBirth!=='dd-mm-yyyy' && this.state.errors.dobError==='')
    || (this.state.name!=='' && this.state.userEmail!=='' && emailRegex.test(this.state.userEmail.toLowerCase()) && this.state.dateOfBirth!=='dd-mm-yyyy' && this.state.errors.dobError==='')) {
      this.setState({loginPageLoading:true})
      const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const phoneNUmberObject = {
      full_phone_number: `${this.state.countryCode}${this.state.phoneNumber}`,
   }

   const emailObject={
    email:this.state.userEmail.toLowerCase()
   }

   const apiData = this.getAPiData(phoneNUmberObject,emailObject);



    const httpBody = {
      data: apiData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountCreationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
 }

  handleNameChange=(event:{target:{value:string}})=>{
    this.setState({name:event.target.value},()=>{
      this.enableButton()
    })
    if (event.target.value.trim()===''){
      
      this.setState((prevState) => ({
        errors: { ...prevState.errors, nameError:configJSON.nameError}
      }));
     
    }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, nameError: '' }
      }));
    }
   
}

  handlePhoneNumberChange=(event:{target:{value:string}})=>{
    this.setState({apiError:''})
    const countryCode:any = this.state.codesFilter.filter((countryCode) => {
      return countryCode.countryCodes[0] == this.state.countryCode
    });         

      this.setState({phoneNumber:event.target.value},()=>{
        this.enableButton()
      })
      this.timeout!==null && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const mobileNumber = parsePhoneNumberFromString(`+${this.state.countryCode} ${event.target.value}`,countryCode[0].isoCode2);
        if (event.target.value===''){
          this.setState((prevState) => ({
            errors: { ...prevState.errors, phoneNumberError: configJSON.phoneError },formError:configJSON.phoneError
          }));
        }
        else if (!(mobileNumber ? mobileNumber?.isValid() : false)){
          this.setState((prevState) => ({
            errors: { ...prevState.errors, phoneNumberError: configJSON.enterValidNumber },formError:configJSON.enterValidNumber
          }));
        }
        else{
          this.setState((prevState) => ({
            errors: { ...prevState.errors, phoneNumberError: '' },formError:''
          }));
        }
      }, 800);

      if (event.target.value.length > 0 && this.state.formError) {
        this.setState({formError:''})
      }   
  }


  handleInputClick = (event:React.MouseEvent<HTMLElement>) => {
  this.setState({
    anchorEl: event.currentTarget
  })
  };


  handleCloseCalendar=()=>{
    this.setState({
      anchorEl:null
    })
  }

  handleDobChange=(val: Value , event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    const getFormatDate = this.formatDate(val as Date)
    this.handleDob({target: {value: getFormatDate.dobDate}})
    this.setState({
      dateOfBirth:getFormatDate.dobDate,
      calendarDate:val as Date,
      anchorEl:null
    },()=>{ 
      this.enableButton()
    })
    this.validateDob(getFormatDate.validateDate)
    
  }

   formatDate = (date:Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return {validateDate:`${year}/${month}/${day}`, dobDate:`${day}/${month}/${year}`};
  };

  validateDob=(value:string)=>{
    const parts = value.split('/');
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const currentDay = parseInt(parts[0], 10);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentDateOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1 
    if ((currentDay>=currentDateOfMonth && year>=currentYear && month>=currentMonth)||
    (currentDay>=currentDateOfMonth && year>currentYear && month<currentMonth)||
    (month>currentMonth && year>=currentYear && currentDay<currentDateOfMonth)||
    (year>currentYear)){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate},formError:''
      }),()=>{
        this.enableButton()
      })
    }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError: '' },formError:''
      }),()=>this.enableButton());

    }
  }

  handleChangeSuggestion=()=>{
    this.setState({showSuggestion:!this.state.showSuggestion,userEmail:'',phoneNumber:'',formError:'',apiError:''},()=>{
      this.enableButton()
    })
 }

 enableButton=()=>{
  const countries:any = this.state.codesFilter.filter((countryCode) => {
    return countryCode.countryCodes[0] == this.state.countryCode
  });        

  const isMobile = parsePhoneNumberFromString(`+${this.state.countryCode} ${this.state.phoneNumber}`,countries[0].isoCode2);
  
  if ((
  isMobile?.isValid() && this.state.name.trim()!=='' 
  && this.state.errors.dobError==='' && this.state.dateOfBirth!==''

  ) || (
    this.state.name.trim()!==''
    && configJSON.emailRegex.test(this.state.userEmail.toLowerCase()) && this.state.errors.dobError==='' && this.state.dateOfBirth!==''
    ) && this.state.checked ) {

    this.setState({disable:false})
  }
  else{
    this.setState({disable:true})
  }
 }



 handleEmailChange=(event:{target:{value:string}})=>{
  this.setState({apiError:''})
  const emailRegex=configJSON.emailRegex
  this.setState({userEmail:event?.target.value})
  this.timeout!==null && clearTimeout(this.timeout);
  this.timeout = setTimeout(() => {
    if (event?.target.value.trim()===''){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, emailError: configJSON.enterEmail},formError:configJSON.emailError
      }));
    }
    else if (!emailRegex.test(event?.target.value.toLowerCase())){
     this.setState((prevState) => ({
       errors: { ...prevState.errors, emailError: configJSON.enterValidEmail },formError:configJSON.enterValidEmail
     }));
   }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, emailError: '' },formError:''
      }));
    }
  }, 800);

  if (event.target.value.length > 0 && this.state.formError) {
    this.setState({ formError: '' })
  } 
  
 this.enableButton()

 }

navigateToLogin=()=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    this.send(message);
    
}

navigateToHome=()=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    this.send(message);
    
}

createUserWithGoogle=(token:string)=>{
  const deviceNewId=new uuId.DeviceUUID().get()
  const header = {
    "Content-Type": configJSON.contentTypeApiAddDetail
  };
  const apiDetails = {
   attributes:{id_token:token,platform:"google",device_id:deviceNewId}
  };

  const httpBody = {
    data: apiDetails,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.googleAccountApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.googleAuthEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeAddDetail
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

fetchProfile=()=>{
  const { userGoogle } = this.state;
  const token=userGoogle?.credential||''
  this.createUserWithGoogle(token)
  const decoded:{family_name:string,given_name:string,email:string,photo:string} = jwt_decode(token);
  this.setState({profileGoogle:{firstName:decoded?.family_name,lastName:decoded?.given_name,email:decoded?.email,photo:decoded?.photo}})
}

handleLoginSuccess = (credentialResponse:CredentialResponse) => {
  this.setState({userGoogle: credentialResponse },()=>this.fetchProfile());  
};

handleLoginError = () => {
 this.setState({error:'Login error'})
};
handleApiRespOfLogin = (responseJson:any)=>{
  setStorageData('authToken',responseJson.meta.token)
  this.getLoggedInUserSignup(responseJson)
  this.handleMultiUser(responseJson)
  
}

handleAppleLoginSuccess = (credentialResponse:any) => {
  console.log("apple accounts login resp:", credentialResponse); 
  window.localStorage.setItem("IsLoggedinFromApple", "true")
  const updatedResp = {
    ...credentialResponse,
    meta: {
      token: credentialResponse?.meta?.tokens?.login, // Correctly accesses the login token
      refresh_token: credentialResponse?.meta?.tokens?.refresh, // Correctly accesses the refresh token
    },
  };
  console.log("updated resp",updatedResp);
  
  this.handleApiRespOfLogin(updatedResp)
};

handleClose = () => {
  this.setState({ openModal: false, privacyModel: false });
};

handleTermsAndConditions = () => {
  this.setState({
    openModal: false,
    privacyModel:false,
  });
};
navigateToTermsAndConditions = () => {
  this.setState({ openModal: true });
};

navigateToPrivacyPolicy =() => {
  this.setState({ privacyModel: true });
}

handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ checked: event.target.checked }, () => {
    this.enableButton();
  });
  localStorage.setItem("is_terms_and_conditions_accepted", JSON.stringify(event.target.checked))
  
  if (event.target.checked === false) {
    this.setState({ checkboxError: configJSON.termsAndConditions });
  } else {
    this.setState({disable:false})
  }
};

handleDob=(e:{target:{value:string}})=>{
const dateFormat =/^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/;
this.timeout!==null && clearTimeout(this.timeout);
  this.timeout = setTimeout(() => {
    if((e.target.value.length===1)||(e.target.value.length>1 &&!dateFormat.test(e.target.value))){
      this.setState((prevState)=>({errors: { ...prevState.errors, dobError:configJSON.dobErrorText},formError:''}),()=>{
        this.enableButton()
      })
    }
    else{
      this.setState((prevState)=>({errors: { ...prevState.errors, dobError:''},formError:''}))
      this.setState({dateOfBirth:e.target.value},()=>{
        this.validateDob(e.target.value.replace(/\//g, '/'))
        this.enableButton()
      })
    }
  },800)

  if (e.target.value.length > 0 && this.state.formError) {
    this.setState({ formError: '' })
  } 

}

  // Customizable Area End
}
