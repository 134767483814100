import { Box, Button, IconButton, Modal, styled, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import GroupsSettings from "./GroupsSettings.web";
import { TTabGroupScreen } from "../../blocks/groups/src/GroupsController";
import GroupsPermission from "../../blocks/groups/src/GroupsPermission.web";
import GroupsListMember from "./GroupsListMember.web";
import ImageRenderer from "./ImageRenderer.web";
import { IConversationPermissions, IConversationSettings } from "../../blocks/chat/src/ViewChatController";
import { addMembersGroup, getGroupMembers, getListUserFriendComet, removeMemberGroup } from "./CometChat";
import GroupsMemberListSection from "./GroupsMemberListSelection.web";
import clsx from "clsx";
import MediaContainer from "./Chats/MediaContainer.web";

const leftArrow = require("./assets/leftArrow.svg");
const groupIcon = require("./assets/groupIcon.svg")
const cameraIcon = require("./assets/cameraIcon.svg")
const untickIcon = require("./assets/untickIcon.svg");
const tickIcon = require("./assets/tickIcon.svg")

interface IGroupsEdit {
    scope: string;
    guid: string;
    checked?: boolean;
    isOpen: boolean;
    isCanEdit: boolean;
    groupsSettings: IConversationSettings;
    groupsPermissions: IConversationPermissions;
    isOpenModalGroupSubscription: boolean;
    onUploadAttachment: (imageFile: File) => Promise<void>;
    onToggleModalSubscription: (isOpenModalDisappearing: boolean) => void;
    onToggleModalEditGroup: (isBool: boolean) => void;
    onUpdateGroupSetting: (groupSettings: Partial<IConversationSettings>) => Promise<void>;
    onUpdateGroupPermisssions: (groupPermissions: Partial<IConversationPermissions>) => Promise<void>;
    handleCreateGroupPlan: (id: string) => void;
}

function GroupsEdit({
    scope,
    guid,
    checked,
    isOpen,
    isCanEdit,
    groupsSettings,
    groupsPermissions,
    isOpenModalGroupSubscription,
    onUploadAttachment,
    onToggleModalSubscription,
    onToggleModalEditGroup,
    onUpdateGroupSetting,
    onUpdateGroupPermisssions,
    handleCreateGroupPlan,
}: IGroupsEdit) {

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [currentScreen, setCurrentScreen] = useState<TTabGroupScreen>("edit");
    const [isEdit, setIsEdit] = useState(false);
    const [nameGroupEdit, setNameGroupEdit] = useState("");
    const [descriptionGroupEdit, setDescriptionGroupEdit] = useState("");
    const [listMember, setListMember] = useState<CometChat.User[]>([]);
    const [listContact, setListContact] = useState<CometChat.User[]>([]);
    const [listMemberSelected, setListMemberSelected] = useState<CometChat.User[]>([]);
    const [checkedContacts, setCheckedContacts] = useState<{ [key: string]: boolean }>({});
    const [listMemberCanNotSendMessage, setListMemberCanNotSendMessages] = useState<string[]>([]);
    const [isCanSendMessage, setIsCanSendMessage] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            handleResetModalData();
            return;
        }

        getListMemberGroup();
    }, [isOpen])

    useEffect(() => {
        setNameGroupEdit(groupsSettings.name || "");
        setDescriptionGroupEdit(groupsSettings.description || "")
    }, [groupsSettings])

    useEffect(() => {
        if (!groupsPermissions?.cannot_send_messages) return;
        setIsCanSendMessage(groupsPermissions?.cannot_send_messages.length !== listMember.length);
        setListMemberCanNotSendMessages(groupsPermissions?.cannot_send_messages);
    }, [groupsPermissions])

    useEffect(() => {
        const listSelectedMember = Object.entries(checkedContacts);
        const listMember = listSelectedMember.map(([uid]) => {
            const member = listContact.find(contact => contact.getUid() === uid && checkedContacts[uid]);
            return member;
        }).filter(Boolean);

        setListMemberSelected(listMember as CometChat.User[]);
    }, [checkedContacts])


    const getListMemberGroup = async () => {
        try {
            const listMemberGroup = await getGroupMembers(guid) ?? [];
            setListMember(listMemberGroup);
            const listUsers = await getListUserFriendComet({});
            const filteredUsers = listUsers.filter(user =>
                !listMemberGroup.some(member => member.getUid() === user.getUid())
            );
            setListContact(filteredUsers);

        } catch (err) {
            console.error("Fetch get list member failed with err", err)
        }
    }

    const handleResetModalData = () => {
        setCurrentScreen("edit");
        setIsEdit(false);
        setNameGroupEdit(groupsSettings.name || "");
        setDescriptionGroupEdit(groupsSettings.description || "");
        setImageSrc(null);
    }

    const handleToggleEditBtn = () => {
        if (isEdit) {
            if (!nameGroupEdit) return;
            onUpdateGroupSetting({
                name: nameGroupEdit,
                description: descriptionGroupEdit
            })
        }
        setIsEdit(!isEdit);
    }

    const handleChangeDescriptionGroup = (value: string) => {
        setDescriptionGroupEdit(value);
    }

    const handleChangeGroupScreen = (tabScreen: TTabGroupScreen) => {
        setCurrentScreen(tabScreen);
        setCheckedContacts({})
    }

    const handleOverlayClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
            try {
                await onUploadAttachment(file);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleChangeGroupsPermission = (groupsPermissionSetting: IConversationPermissions, settingKeyValue: string) => {
        if (settingKeyValue !== "cannot_send_messages") {
            const updateGroupsPermission = { ...groupsPermissionSetting, cannot_send_messages: groupsPermissions?.cannot_send_messages };
            onUpdateGroupPermisssions(updateGroupsPermission);
            return;
        }
        const { cannot_send_messages } = groupsPermissionSetting;
        if (!cannot_send_messages) {
            const updateGroupsPermission = { ...groupsPermissionSetting, cannot_send_messages: listMember.map(member => member.getUid()) };
            setIsCanSendMessage(false);
            onUpdateGroupPermisssions(updateGroupsPermission)
            return;
        }
        const updateGroupsPermission = { ...groupsPermissionSetting, cannot_send_messages: [] };
        setIsCanSendMessage(true);
        onUpdateGroupPermisssions(updateGroupsPermission);
    }

    const handleToggleUserSendMessage = (uid: string) => {
        const updateListMemberCanNotSendMessage = [...listMemberCanNotSendMessage];
        const isExist = updateListMemberCanNotSendMessage.find(memberUid => memberUid === uid);
        if (!isExist) {
            updateListMemberCanNotSendMessage.push(uid);
            const updateGroupsPermission = { ...groupsPermissions, cannot_send_messages: updateListMemberCanNotSendMessage };
            setListMemberCanNotSendMessages(updateListMemberCanNotSendMessage);
            onUpdateGroupPermisssions(updateGroupsPermission);
            return;
        }
        const removeUid = updateListMemberCanNotSendMessage.filter(messageUid => messageUid !== uid);
        const updateGroupsPermission = { ...groupsPermissions, cannot_send_messages: removeUid };
        setListMemberCanNotSendMessages(removeUid);
        onUpdateGroupPermisssions(updateGroupsPermission);
    }

    const handleCheckboxChange = (contactId: string) => {
        setCheckedContacts((prevState) => ({
            ...prevState,
            [contactId]: !checkedContacts[contactId]
        }));
    };

    const onRemoveMember = (contactId: string) => {
        const updateCheckedContacts = { ...checkedContacts };
        delete updateCheckedContacts[contactId];
        setCheckedContacts(updateCheckedContacts);
    };

    const handleAddMembersGroup = async () => {
        try {
            const response = await addMembersGroup({ guid, listMember: listMemberSelected });
            if (response && Object.keys(response).length) {
                await getListMemberGroup()
            }
            handleResetModalData();
        } catch (err) {
            console.error("Add member fail with error", err)
        }
    }

    const handleRemoveMember = async (memberUid: string) => {
        try {
            const isRemoved = await removeMemberGroup({ guid, uid: memberUid });
            if (isRemoved) {
                await getListMemberGroup();
            }
            handleResetModalData();
        } catch (err) {
            console.error("Remove member fail with error", err)
        }
    }

    const renderHeaderFunction = () => {
        switch (currentScreen) {
            case "add_members": {
                return <Button className="btnAddMember" onClick={() => handleAddMembersGroup()}>
                    Add
                </Button >
            }
        }
    }

    const renderHeader = () => {
        const listTabScreen: {
            tab: TTabGroupScreen;
            label: string;
            backTo?: TTabGroupScreen;
        }[] = [
                {
                    tab: "edit",
                    label: groupsSettings.name || ""
                },
                {
                    tab: "group_permission",
                    label: "Group Permission",
                    backTo: "edit"
                },
                {
                    tab: "send_messages",
                    label: "Send Messages",
                    backTo: "group_permission"
                },
                {
                    tab: "add_members",
                    label: groupsSettings.name || "",
                    backTo: "edit"
                },
                {
                    tab: "gallery",
                    label: "Gallery",
                    backTo: "edit"
                }
            ]

        const currentTabInfo = listTabScreen.find((tab: any) => tab.tab === currentScreen);
        const backToScreen = currentTabInfo?.backTo;
        const labelScreen = currentTabInfo?.label || "Name Group";
        const isCurrentGalleryTab = currentTabInfo?.tab === "gallery";

        return <Box className="headerContainer" style={{ borderBottomWidth: isCurrentGalleryTab ? 0 : 1, marginBottom: isCurrentGalleryTab ? 15 : 30 }}>
            <IconButton className="backBtn" onClick={() => {
                if (!backToScreen) {
                    onToggleModalEditGroup(isOpen);
                    return;
                }
                handleChangeGroupScreen(backToScreen)
            }}>
                <ImageRenderer src={leftArrow} width={24} height={24} alt="i" style={{ color: checked ? "#222222" : "#E9E9E9" }} />
            </IconButton>
            <Box className="header__title">
                <Typography className={`${clsx(checked && "lightThemePrimaryTextColor")}`}>
                    {labelScreen}
                </Typography>
            </Box>
            <Box className="header__function">
                {renderHeaderFunction()}
            </Box>
        </Box>
    }

    const renderEditGroupInformation = () => {
        return <Box className="groupsInformationContainer">
            <Box className="groupsPicture">
                <ImageRenderer width={94} height={94} src={imageSrc || groupsSettings.icon || groupIcon} alt="i" />
                {isCanEdit && <Box
                    className="overlayEditPicture"
                    onClick={handleOverlayClick}
                >
                    <ImageRenderer src={cameraIcon} width={24} height={24} alt="i" />
                    <span className="textSpan">Edit picture</span>
                </Box>
                }
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </Box>
            <Box className="groupsInformation">
                <Box className="groupsNameContainer">
                    {isEdit
                        ? <TextField
                            className="textInputGroupName"
                            variant="outlined"
                            fullWidth
                            value={nameGroupEdit}
                            InputProps={{
                                endAdornment: (
                                    <ImageRenderer
                                        src={nameGroupEdit ? tickIcon : untickIcon}
                                        alt="i"
                                        width={24}
                                        height={24}
                                    />
                                )
                            }}
                            onChange={(e) => {
                                const { value } = e.target;
                                setNameGroupEdit(value);
                            }}
                        />
                        :
                        <Typography className={`${clsx(checked && "lightThemePrimaryTextColor")}`}>
                            {groupsSettings.name}
                        </Typography>
                    }
                </Box>
                <Typography className="groupsMemberCount">
                    {listMember?.length} {listMember.length === 1 ? "member" : "members"}
                </Typography>
            </Box>
            {
                (scope === "admin" || groupsPermissions.can_edit_group_settings) && <Box>
                    <Button
                        className={`editBtn`}
                        onClick={handleToggleEditBtn}>
                        <Typography className={`${clsx(checked && "gradientTextColor")}`}>
                            {isEdit ? "Done" : "Edit"}
                        </Typography>
                    </Button>
                </Box>
            }
        </Box>
    }

    const renderContainer = () => {
        switch (currentScreen) {
            case "edit": {
                return <Fragment>
                    {renderEditGroupInformation()}
                    <GroupsSettings
                        scope={scope}
                        checked={checked}
                        isEditGroup={isEdit}
                        isCanEdit={groupsPermissions.can_edit_group_settings}
                        isOpenModalSubscription={isOpenModalGroupSubscription}
                        groupDescriptionValue={descriptionGroupEdit}
                        onChangeGroupDescription={handleChangeDescriptionGroup}
                        onToggleModalSubscription={onToggleModalSubscription}
                        onChangeGroupScreen={handleChangeGroupScreen}
                        handleCreateGroupPlan={() => handleCreateGroupPlan(guid)}
                    />
                    <GroupsListMember
                        checked={checked}
                        typeList="edit_group"
                        isAdminPermission={scope === "admin"}
                        listMember={listMember}
                        listUserCanNotSendMessage={listMemberCanNotSendMessage}
                        onHandleChangeScreen={handleChangeGroupScreen}
                        onHandleRemoveMember={handleRemoveMember}
                    />
                </Fragment>
            }
            case "group_permission": {
                return <GroupsPermission
                    isCanSendMessage={isCanSendMessage}
                    checked={checked}
                    defaultSetting={groupsPermissions}
                    onChangeSetting={handleChangeGroupsPermission}
                    onChangeGroupScreen={handleChangeGroupScreen}
                />
            }
            case "send_messages": {
                return <GroupsListMember
                    checked={checked}
                    listUserCanNotSendMessage={listMemberCanNotSendMessage}
                    typeList="permission"
                    listMember={listMember}
                    onToggleUserSendMessage={handleToggleUserSendMessage}
                />
            }
            case "add_members": {
                return <GroupsMemberListSection
                    checked={checked}
                    listMemberSelected={listMemberSelected}
                    listContact={listContact}
                    checkedContacts={checkedContacts}
                    onCheckboxChange={handleCheckboxChange}
                    onRemoveMember={onRemoveMember}
                />
            }
            case "gallery": {
                return <MediaContainer
                    guid={guid}
                    checked={checked}
                />
            }
        }
    }

    return <Modal
        open={isOpen}
        onClose={onToggleModalEditGroup}
        keepMounted={false}
    >
        <GroupsEditStyled style={{
            background: checked ? "#FFFFFF" : "#0B0B0B"
        }} >
            {renderHeader()}
            <Box className="groupsEditContainer">
                {renderContainer()}
            </Box>
        </GroupsEditStyled>
    </Modal>
}

const GroupsEditStyled = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "630px",
    height: "730px",
    border: '1px solid #35383F',
    borderRadius: "10px",
    padding: "20px 24px",
    outline: "none",
    "& .lightThemePrimaryTextColor": {
        color: "#222222!important"
    },
    "& .headerContainer": {
        display: "flex",
        alignItems: "center",
        position: "relative",
        height: "62px",
        margin: "0px 12px",
        marginBottom: "30px",
        borderBottom: "1px solid #222222",
        "& .backBtn": {
            position: "absolute",
            left: "-12px",
        },
        "& .header__title": {
            textAlign: "center",
            padding: "0 48px",
            flex: 1,
            overflow: "hidden",
            "& p": {
                fontFamily: "Manrope",
                fontWeight: 800,
                fontSize: 18,
                color: "#FFFFFF",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }
        },
        "& .header__function": {
            position: "absolute",
            right: 0,
            "& .btnAddMember": {
                textTransform: "capitalize",
                fontFamily: "Manrope",
                fontSize: 16,
                fontWeight: 600,
                color: "#8833FF",
                marginRight: "-16px",
            }
        }
    },
    "& .groupsEditContainer": {
        height: "calc(730px - 62px - 30px)",
        overflowY: "auto",
        "& .groupsInformationContainer": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: 20,
            marginBottom: "10px",
            "& .groupsPicture": {
                width: "100px",
                height: "100px",
                boxSizing: "border-box",
                border: "3px solid rgb(255, 255, 255)",
                borderRadius: "100px",
                position: "relative",
                "& img": {
                    borderRadius: "100px"
                },
                "& .overlayEditPicture": {
                    cursor: 'pointer',
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    borderRadius: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .textSpan": {
                        fontFamily: "Manrope",
                        fontWeight: 700,
                        fontSize: 8,
                        color: "#FFFFFF"
                    }
                }
            },
            "& .groupsInformation": {
                textAlign: "center",
                "& .groupsNameContainer": {
                    "& p": {
                        fontFamily: "Manrope",
                        fontWeight: 500,
                        fontSize: 24,
                        color: "#FFFFFF",
                    },
                    "& .textInputGroupName": {
                        width: '335px',
                        '& .MuiOutlinedInput-root': {
                            position: 'relative',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            height: "56px",
                            '& fieldset': {
                                border: 'none',
                            },
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                padding: '2px',
                                borderRadius: 'inherit',
                                background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
                                WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                WebkitMaskComposite: 'xor',
                                maskComposite: 'exclude',
                                pointerEvents: 'none',
                                zIndex: 0,
                            },
                            '& .MuiOutlinedInput-input': {
                                position: 'relative',
                                zIndex: 1,
                                boxSizing: 'border-box',
                            },
                        },
                        '& .MuiOutlinedInput-input': {
                            fontFamily: 'Manrope',
                            fontSize: '24px',
                            fontWeight: 500,
                            lineHeight: '28px',
                        },
                    }
                },
                "& .groupsMemberCount": {
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: 18,
                    color: "#6B6B6B"
                }
            },
            "& .editBtn": {
                position: 'relative',
                padding: '4px 8px',
                borderRadius: '10px',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
                outline: 'none',
                overflow: 'hidden',
                "& p": {
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#FFFFFF',
                    textTransform: "capitalize",
                },
                "& p.gradientTextColor": {
                    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 'inherit',
                    padding: '2px',
                    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
                    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    WebkitMaskComposite: 'xor',
                    maskComposite: 'exclude',
                    pointerEvents: 'none',
                    zIndex: 0,
                },
                '> *': {
                    position: 'relative',
                    zIndex: 1,
                },

            }
        },
        "& .groupsSettingsStyled": {
            margin: "0 12px",
            borderBottom: "1px solid #6B6B6B",
            "& .settingWrapper": {
                margin: "0"
            },
            "& .settingDescription,.settingWrapper:last-child": {
                marginBottom: "19px"
            }
        },
        "& .groupsListMemberStyled, .groupsPermissionStyled, .groupsMemberListSectionStyled, .mediaContainerStyled": {
            margin: "0 12px",
            "& .container__search": {
                flexDirection: "column-reverse",
            }
        },
    },
}))

export default GroupsEdit